import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { PhoneAuthProvider, RecaptchaVerifier, signInWithCredential, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../services/firebaseconfig";
import Signup from "./Signup";
import { UserExistence } from "../../services/UserExistence";
import toast from "react-hot-toast";

const Login = ({ isOpen, setIsOpen }) => {
  const [err, setErr] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isSignup, setIsSignup] = useState(false);

  // Function to handle the on/off the modal
  const toggle = () => {
    setIsOpen(!isOpen);
  };


  // Function to manage the OTP---
  const handleOtp = async (e) => {
    e.preventDefault();
    setErr("");
    if (!mobile) {
      setErr("Mobile Number is required");
      return;
    }
    if (mobile?.length < 10) {
      setErr("Mobile Number should be of 10 digits");
    }
    const phoneNumber = `+91${mobile}`;
    try {
      // Initialize reCAPTCHA if not already initialized
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,                     // Firebase Auth instance
          "recaptcha-container",    // ID of the reCAPTCHA container
          {
            size: "invisible",      // Invisible reCAPTCHA for seamless UX
            callback: (response) => {
              console.log("reCAPTCHA solved:", response);
            },
          }
        );
        // Render the reCAPTCHA
        await window.recaptchaVerifier.render();
      }
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setVerificationId(confirmationResult?.verificationId);
      setIsOtpSent(true);
      setTimer(60);
      setIsDisabled(true);
      toast.success("OTP has been sent your Mobile Number!");
    } catch (error) {
      setErr("Failed to send OTP. Please try again");
      console.error("Error sending OTP:", error);
    }
  };

  // UseEffect to handle the timer 
  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsDisabled(false);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer]);



  // Function to handle the OTP verification---
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setErr("Please enter the OTP");
      return;
    }
    try {
      setIsLoading(true);
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      const user = auth?.currentUser.uid;
      if (user) {
        const userExists = await UserExistence(user);
        if (userExists) {
          localStorage.setItem("userID", user);
          toast.success("Phone Number has been verified Successfully");
          setIsLoading(false);
          setMobile("");
          setOtp("");
          toggle();
          setErr("");
          setTimer(0);
        } else {
          setIsLoading(false);
          setIsDisabled(false);
          setMobile("");
          setOtp("");
          setTimer(0);
          setIsSignup(true);
          setErr("");
          toggle();
        }
      }
    } catch (error) {
      setIsLoading(false);
      setErr("Invalid OTP. Please try again.");
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody>
          <form onSubmit={handleVerifyOtp}>
            <div className="row p-1">
              <div className="col-lg-5 ">
                <div className="imageHeightContainer">
                  <img src="/images/login.png" alt="loginbus" width="100%" />
                </div>
              </div>
              <div className="col-lg-7 pt-md-5 pt-3">
                <div className="container">
                  <div className="d-flex flex-column p-md-4 p-2">
                    <div className="authHeader">Let the Journey Begin!</div>
                    <div className="mt-3 authSubHeader">
                      Unlock a world of Nature with a single click! Please log
                      in to your account.
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="form-label authLabels mb-2">
                        Mobile Number
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group position-relative text-center">
                        <input
                          type="number"
                          required
                          className="form-control py-2 rounded pe-5"
                          placeholder="Enter your number"
                          value={mobile}
                          onChange={(e) => {
                            setMobile(e.target.value);
                            if (e.target.value) {
                              setErr("");
                            }
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value?.slice(0, 10);
                          }}
                        />
                        {verificationId && mobile ? (
                          <span
                            className="position-absolute end-0 top-50 translate-middle-y pe-3 rounded-end"
                            style={{
                              zIndex: 100,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontSize: "12px",
                              cursor: isDisabled ? "not-allowed" : "pointer",
                              color: isDisabled ? "lightgray" : "black",
                            }}
                            onClick={isDisabled ? null : handleOtp}
                          >
                            {isDisabled ? `Resend OTP ${timer}` : "Resend OTP"}
                          </span>
                        ) : (
                          <span
                            className="position-absolute end-0 top-50 translate-middle-y pe-3 rounded-end"
                            style={{
                              zIndex: 100,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontSize: "12px",
                            }}
                            onClick={handleOtp}
                          >
                            Get OTP
                          </span>
                        )}
                      </div>
                      {err && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {err}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="form-label authLabels mb-2">
                        OTP
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        required
                        className="form-control py-2 rounded "
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value?.slice(0, 6);
                        }}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <button
                        type="submit"
                        className="btn btn-auth w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <div>Don’t have an account? </div>
                        <div
                          className="authLabels mx-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setIsSignup(true);
                          }}
                        >
                          Sign Up
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Signup isOpen={isSignup} setIsOpen={setIsSignup} mobile={mobile} />
    </div>
  );
};

export default Login;
