import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoIosWarning } from "react-icons/io";

const ErrorModal = ({ isOpen, message, setIsOpen }) => {
    const toggle = () => {
        setIsOpen(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} centered size="md">
                <ModalBody>
                    {/* <div className=''>
                        <img src="logo.png" alt="Logo" width="25%" />
                    </div> */}
                    <div className='d-flex align-items-center justify-content-center'>
                        <IoIosWarning color='red' size="60" />
                    </div>
                    <div
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className='d-flex align-items-center justify-content-center'>
                        Whoops!
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-3'>
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            {message}
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-3'>
                        <div style={{ fontSize: "20px", fontWeight: "600", color: "rgba(33, 136, 33, 1)" }}>
                            LEAFYBUS
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-1'>
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            Thank you!
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ErrorModal