import React from "react";
import Slider from "react-slick/lib/slider";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { PiAmbulance } from "react-icons/pi";
import { IoFemaleOutline, IoTimeSharp } from "react-icons/io5";
import { PiPoliceCar } from "react-icons/pi";
import { TbTournament } from "react-icons/tb";
import { MdClose } from "react-icons/md";

const TourModal = ({ isOpen, setIsOpen, info }) => {
  const toggle = () => setIsOpen();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: { slidesToShow: 3, slidesToScroll: 1, dots: true },
      },
      {
        breakpoint: 1400,
        settings: { slidesToShow: 3, slidesToScroll: 1, dots: true },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2, slidesToScroll: 1, dots: true },
      },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1, dots: true },
      },
    ],
  };

  const tourCard = [
    {
      id: "1",
      image: "/images/tour1.png",
      heading: "Har ki Pauri",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "2",
      image: "/images/tour2.png",
      heading: "Mansa Devi Mandir",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "3",
      image: "/images/tour3.png",
      heading: "Ganesh Ghat",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "4",
      image: "/images/tour4.png",
      heading: "Rajaji National Park",
      desc: "Supporting description for the card goes here like a breeze.",
    },
  ];

  const tourCard2 = [
    {
      id: "1",
      image: "/images/tour5.png",
      heading: "Dosa Plaza",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "2",
      image: "/images/tour6.png",
      heading: "Big Ben Restaurant",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "3",
      image: "/images/tour7.png",
      heading: "Invitation ",
      desc: "Supporting description for the card goes here like a breeze.",
    },
    {
      id: "4",
      image: "/images/tour8.png",
      heading: "Fun-N-Food",
      desc: "Supporting description for the card goes here like a breeze.",
    },
  ];

  const serviceCard = [
    {
      id: "1",
      icon: <PiAmbulance size={24} />,
      name: "Ambulance",
      num: "108",
    },
    {
      id: "2",
      icon: <PiPoliceCar size={24} />,
      name: "Police",
      num: "100",
    },
    {
      id: "3",
      icon: <IoFemaleOutline size={24} />,
      name: "Helpline",
      num: "1090",
    },

    {
      id: "4",
      icon: <TbTournament size={24} />,
      name: "Tour",
      num: "111",
    },
  ];

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} centered size="xl" scrollable>
        <ModalBody>

          <div className="row">
            <div className="text-end mt-0" onClick={toggle} style={{ cursor: "pointer" }}>
              <MdClose size={20} />
            </div>
            <div className="col-lg-5">
              <img
                src="/images/tour.png"
                alt="tourplace"
                width="100%"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />
            </div>
            <div className="col-lg-7">
              <div
                className="mt-2 tourHeading"
              >
                {info}
              </div>
              <div
                className="mt-2"
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                One of the seven holiest places for Hindus,Haridwar literally
                means a 'gateway to God', succinctly capturing its pious and
                spiritual character. Come here for an epiphanic union with the
                almighty, or just sit by the scintillating river Ganga and watch
                your stress float away with the swishing water and a cool,
                balming breeze.
              </div>
              <div
                className="mt-3"
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontWeight: 600,
                  fontSize: "24px",
                }}
              >
                What to{" "}
                <span
                  style={{
                    color: "rgba(2, 142, 2, 1)",
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  See
                </span>
              </div>

              <Slider {...settings} className="w-100 mt-4">
                {tourCard?.map((item) => (
                  <div
                    key={item?.id}
                    className="d-flex align-items-center justify-content-center text-white"
                  >
                    <TourCard item={item} />
                  </div>
                ))}
              </Slider>

              <div
                className="mt-2"
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontWeight: 600,
                  fontSize: "24px",
                }}
              >
                Where to{" "}
                <span
                  style={{
                    color: "rgba(2, 142, 2, 1)",
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  Eat
                </span>
              </div>
              <Slider {...settings} className="w-100 mt-4">
                {tourCard2?.map((item) => (
                  <div
                    key={item?.id}
                    className="d-flex align-items-center justify-content-center text-white"
                  >
                    <TourCard item={item} />
                  </div>
                ))}
              </Slider>

              <div className="row mt-2">
                {serviceCard.map((item) => {
                  return (
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center mt-xl-0 mt-1">
                      <div className="d-flex align-items-center ">
                        {item.icon}
                      </div>
                      <div style={{ marginLeft: "2px" }}>{item.name}:</div>
                      <div style={{ marginLeft: "2px" }}>{item.num}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TourModal;

export const TourCard = ({ item }) => {
  return (
    <React.Fragment>
      <div
        className=""
        style={{
          backgroundImage: `url(${item?.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "90%",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            marginTop: "9rem",
            background: "rgba(0, 0, 0, 0.5)",
            padding: "15px",
          }}
          className=""
        >
          <div className="" style={{ fontSize: "14px", fontWeight: 600 }}>
            {item.heading}
          </div>
          <div className="" style={{ fontSize: "8px", fontWeight: 400 }}>
            {item.desc}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
