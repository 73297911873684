import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export const ConfettiComponent = () => {
    const { width, height } = useWindowSize();
    return (
        <Confetti
            width={width}
            height={height}
            numberOfPieces={100}
            gravity={0.3}
            colors={['#ff0000', '#ffff00', '#00ff00', '#0000ff']}
            recycle={false}
            confettiSource={{
                x: width / 2 - 100,
                y: height / 2 - 100,
                w: 200,
                h: 100,
            }}
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 1070,
            }}
            drawShape={(ctx) => {
                const size = 6;
                ctx.beginPath();
                ctx.moveTo(0, -size);
                ctx.lineTo(size, 0);
                ctx.lineTo(0, size);
                ctx.lineTo(-size, 0);
                ctx.closePath();
                ctx.fill();
            }}
        />
    );
};
