import CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';


// Function to calculate the total FARE----
export const totalFare = (arr) => {
    return arr?.reduce((total, item) => total + (item?.fare || 0), 0);
}

// functon to calculate the FARE with GST---
export const calculateFareWithGST = (arr, couponValue, gstPercent) => {
    //  Calculate the total fare before applying any discounts
    const initialResult = arr?.reduce(
        (acc, item) => {
            const fare = item?.fare || 0;
            acc.totalFare += fare;
            return acc;
        },
        { totalFare: 0 }
    );

    // Step 2: Apply discounts on the total fare
    let totalDiscount = 0;
    let discountedFare = initialResult.totalFare; // Start with the total fare

    if (couponValue?.flatOff) {
        const maxDiscount = parseFloat(couponValue?.flatOff) || 0;
        // Ensure the flat discount is applied only once
        totalDiscount = Math.min(maxDiscount, discountedFare);
        discountedFare -= totalDiscount;
    } else if (couponValue?.percentage) {
        const percentageDiscount = couponValue?.percentage || 0;
        const maxDiscount = parseFloat(couponValue?.upto) || 0;

        // Calculate the percentage discount on the total fare
        const discount = (discountedFare * percentageDiscount) / 100;
        if (maxDiscount !== 0) {
            totalDiscount = Math.min(discount, maxDiscount);
            discountedFare -= totalDiscount;
        }
        else {
            totalDiscount = discount;
            discountedFare -= totalDiscount;
        }

    }
    // Step 3: Calculate GST on the discounted fare
    const totalTax = discountedFare * (gstPercent / 100 || 0);
    const totalTicket = discountedFare + totalTax; // Final total after discount and GST

    // Step 4: Return final totals
    return {
        totalTicket: parseFloat(totalTicket.toFixed(2)), // Total after discounts and GST
        totalTax: parseFloat(totalTax.toFixed(2)), // GST applied on discounted fare
        totalDiscount: parseFloat(totalDiscount.toFixed(2)) // Total discount applied
    };
};
// Function to generate SHA512 hash -- for the payment integration--
export const generateHash = (hashSequence) => {
    return CryptoJS.SHA512(hashSequence).toString(CryptoJS.enc.Hex);
};
//Function to generate the ticket token--
export const generateTicketToken = (ticketNumber) => {
    const passphrase = "pass";
    const aesKey = CryptoJS.SHA256(passphrase).toString(CryptoJS.enc.Hex);
    const keyBytes = CryptoJS.enc.Hex.parse(aesKey);
    const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");
    // Encrypt data using AES
    const encrypted = CryptoJS.AES.encrypt(ticketNumber, keyBytes, { iv: iv }).toString();
    // Convert Base64 to Base64 URL encoding and remove padding
    const base64UrlToken = encrypted
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    return base64UrlToken;
};

// Function to format the date with day-- in a particualr format----
export const formatDateWithDay = (dateString) => {
    try {
        const [day, month, year] = dateString?.split('/').map(part => part.trim());
        if (!day || !month || !year) {
            throw new Error('Date components missing');
        }
        const numericDay = Number(day);
        const numericMonth = Number(month);
        const numericYear = Number(year);
        if (
            !Number.isInteger(numericDay) ||
            !Number.isInteger(numericMonth) ||
            !Number.isInteger(numericYear)
        ) {
            throw new Error('Date components must be valid numbers');
        }
        // JavaScript months are 0-based, so subtract 1 from the month
        const date = new Date(numericYear, numericMonth - 1, numericDay);
        if (isNaN(date.getTime())) {
            throw new Error('Invalid Date: Unable to parse');
        }
        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
        console.error('Error in formatDateWithDay:', error.message);
        return 'Invalid Date';
    }
};

// Function to formatDate in specific format---
export const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

// function for mininum attribute--
export const updatedFormat = () => {
    const timeZone = 'Asia/Kolkata';
    const now = new Date();
    const istDate = toZonedTime(now, timeZone);
    return format(istDate, 'yyyy-MM-dd');
};

// Function to handle the varous cases of the status--
export const handleNonSuccessStatus = (status, navigate) => {
    let message;
    switch (status) {
        case "userCancelled":
            message = "Payment was cancelled! Please try again";
            break;
        case "dropped":
            message = "The transaction is marked as dropped. Please try again.";
            break;
        case "bounced":
            message = "The transaction is marked as bounced. Please try again.";
            break;
        case "failure":
            message = "The transaction is marked as failed. Please try again.";
            break;
        default:
            return;
    }
    navigate("/", { state: { showModal: true, message } });
};





