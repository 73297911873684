import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import Connect from "../../components/Connect";
import { getPNRData } from "../../services/UserExistence";
import { formatDateWithDay } from "../../helper";
import PdfUI from "./PdfUI";

const ManageBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pnrData = location.state ? location.state[0] : null;
  const [passengerDetails, setPassengerDetails] = useState();
  const [passenger, setPassenger] = useState([]);

  useEffect(() => {
    if (!pnrData) {
      navigate('/');
      return
    }
    setPassengerDetails(pnrData ? pnrData?.passenger_details : null)
    setPassenger(pnrData?.passenger_details?.map((passenger) => {
      return {
        name: passenger?.name,
        phone: passenger?.mobile,
        seatNumber: passenger?.seat_number,
        status: passenger?.ticket_status,
        journey_date: passenger?.travel_date,
      };
    }))
  }, [pnrData, navigate]);


  // pdf Generation
  const pdfContentRef = useRef();
  const handleDownloadTicket = () => {
    if (pdfContentRef.current) {
      pdfContentRef.current.handleGeneratePDF();
    }
  };




  return (
    <div className="contentSection">
      <div className="container mt-5">
        <div
          className="p-md-4 p-3"
          style={{ background: "rgba(245, 246, 250, 1)", borderRadius: "16px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div><img src="logo.png" alt="LEAYBUS LOGO" width="100px" /></div>
            <div className="bookingHead2">  Electric AC Premium (2+2)</div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="bookingHead3">{formatDateWithDay(pnrData?.travel_date)}</div>
            <div className="bookingHead3">{formatDateWithDay(pnrData?.arr_date)}</div>
          </div>

          <div className="row mt-2">
            <div className="col-3">
              <div className="d-flex flex-column">
                <div className="bookingHead4">{pnrData?.dep_time}</div>
                <div className="bookingHead4">{pnrData?.boarding_details?.address}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column">
                <div className="text-center bookingHead3">{pnrData?.duration}</div>
                <div className="dashBorder2 mt-1">
                  <div className="line2"></div>
                </div>
              </div>
            </div>
            <div className="col-3 text-end">
              <div className="d-flex flex-column">
                <div className="bookingHead4">{pnrData?.arr_time}</div>
                <div className="bookingHead4">{pnrData?.drop_off_details?.address}</div>
              </div>
            </div>

            <div className="mt-5">
              <table className="w-100 table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Name</th>
                    <th className="text-center">Age</th>
                    <th className="text-center">Gender</th>
                    <th className="text-center">Seat</th>
                  </tr>
                </thead>
                <tbody>
                  {passengerDetails && passengerDetails?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center bookingHead3">{item?.name}</td>
                      <td className="text-center bookingHead3" style={{ color: "rgba(96, 96, 96, 1)" }}>
                        {`${item?.age} Yrs`}
                      </td>
                      <td className="text-center bookingHead3" style={{ color: "rgba(96, 96, 96, 1)" }}>
                        {item?.gender === "F" ? "Female" : "Male"}
                      </td>
                      <td className="text-center bookingHead3" style={{ color: "rgba(33, 136, 33, 1)" }}>
                        Seat No. {item?.seat_number}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3 text-center mt-5 bookingHead3 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="bookingHead4">PNR Number:{" "}</div>
                <div className="bookingHead2 ms-2">{pnrData?.ticket_number}</div>
              </div>
              <div className="">LEAFY-Tickets will be sent to:</div>
              <div className="bookingHead2">{passengerDetails ? passengerDetails[0]?.email : null}</div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between mt-5 bookingHead3">
              <div className="">LEAFY-Tickets will be sent to:</div>
              <div className="bookingHead2">{passengerDetails ? passengerDetails[0]?.email : null}</div>
            </div> */}

            <div className=" mt-5 mb-4">
              <div className="row">
                <div className="col-xl 3 col-lg-2 col-md-1 col-3 md-3"></div>
                <div className="col-xl-6 col-lg-8 col-md-10 col-12">
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-5">
                    <div className="p-md-4 p-3 bg-white d-flex align-items-center justify-content-center">
                      {passengerQR({ passenger })}
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <button className="btn btn-secondary mt-3" onClick={handleDownloadTicket}>
                        Download Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-4 container">
        <Connect title={"Request A Call Back !"} />
      </div>
      <div className='' style={{ display: "none" }}>
        <PdfUI
          ref={pdfContentRef}
          ticketInfo={pnrData}
        />
      </div>
    </div>
  );
};

export default ManageBooking;



//  A modal has been created from there user can manage the booking of the tickeby entering the ticket number and mobile.no.
export const BookingModal = ({ isOpen, setIsOpen }) => {
  const [pnr, setPnr] = useState("");
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();
  const toggle = () => {
    setIsOpen(false);
  };


  const handleManageBookings = async (e) => {
    // Navigate to manage bookings after preventing default form submission
    e.preventDefault();
    const payload = {
      pnr: pnr,
      mobile: mobile,
    }

    try {
      const fetchPNR = await getPNRData(payload);
      if (fetchPNR?.length > 0) {
        navigate("/manageBookings-leafybus", { state: fetchPNR });
        toggle(); // Call toggle after navigation
      }
      else {
        toggle(); // Call toggle after navigation
        navigate("/", { state: { showModal: true, message: "Your LEAFY Ticket doesn't Exist! Book and Travel" } });
      }

    } catch (error) {
      navigate("/", { state: { showModal: true, message: "Your LEAFY Ticket doesn't Exist! Book and Travel" } });
      console.log(error);
    }

  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody>
          <form onSubmit={handleManageBookings}>
            <div className="d-flex flex-column p-3">
              <div className=" align-items-center">
                <div className="heading">
                  Please enter the details to
                  <span
                    className="spanHead"
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  >
                    Verify & Print
                  </span>
                  LEAFY Tickets.
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-12 col-12">
                  <label className="form-label bookHeader mb-2">
                    PNR Number
                  </label>
                  <input
                    type="number"
                    required
                    className="form-control py-2 customInput"
                    placeholder="Enter your PNR Number"
                    onChange={(e) => {
                      setPnr(e.target.value)
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value?.slice(0, 5);
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-12 mt-lg-0 mt-3">
                  <label className="form-label bookHeader  mb-2">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    required
                    className="form-control py-2 customInput"
                    placeholder="Enter your Phone Number"
                    onChange={(e) => {
                      setMobile(e.target.value)
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value?.slice(0, 10);
                    }}
                  />
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  type="submit"
                  className="btn btn-secondary"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};


// function to generate the qr code for the booking with particular passenger-----
export const passengerQR = ({ passenger }) => {
  if (!Array.isArray(passenger) || passenger?.length === 0) {
    console.error("Invalid passenger data:", passenger);
    return <div>No valid passenger data available for QR code.</div>;
  }
  const passengerInfo = JSON.stringify(passenger);

  return (
    <div
      style={{
        height: "auto",
        margin: "0 auto",
        maxWidth: 150,
        width: "100%",
        background: "white",
      }}
    >
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={passengerInfo} // Use the passenger info as the QR code value
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};
