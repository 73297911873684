import React, { useEffect, useRef } from 'react'
import QRCode from "react-qr-code";
import Connect from "../../components/Connect";
import { RiBusWifiLine } from "react-icons/ri";
import PdfUI from './PdfUI';
import { useLocation } from 'react-router-dom';
import { Tick } from '../../components/Tickanimation';


const ConfirmationPage = () => {

    const location = useLocation();
    const { ticketInfo, totalTax, totalTicket, totalDiscount } = location.state || {};
    const passengerDetails = ticketInfo?.passenger_details;

    // Now, we can map over the passengerDetails array to create a new array with custom structure
    const passengers = passengerDetails?.map((passenger) => {
        return {
            name: passenger?.name,
            phone: passenger?.mobile,
            seatNumber: passenger?.seat_number,
            status: passenger?.ticket_status,
            journey_date: ticketInfo?.travel_date,
        };
    });

    // pdf Generation
    const pdfContentRef = useRef();
    const handleDownloadTicket = () => {
        if (pdfContentRef.current) {
            pdfContentRef.current.handleGeneratePDF();
        }
    };

    // useEfect Function to get page to the top--
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <React.Fragment>
            <div className="contentSection">
                {/* <div className='mt-5'>
                    <Tickanimation />
                </div> */}
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='mt-md-5 mt-3'>
                        <Tick />
                    </div>
                </div>
                {/* <div className='d-flex align-items-center justify-content-center'>
                    <div className=' mt-md-5 mt-2' style={{ height: "75px", width: "75px", borderRadius: "50%", background: "#31A91D" }}><TiTick color='white' size={75} /></div>
                </div> */}
                <div className='text-center mt-md-4 mt-3 bookingHead2'>Congratulations! You have successfully booked tickets with LEAFYBUS</div>
                <h5 className='text-center mt-4'>Please carry  a relavant ID proof while travelling</h5>
                <div className='container mt-5'>
                    <div className='row p-lg-4 p-md-1 p-3'>
                        <div className='col-md-8 col-12 p-3  pageBorder' style={{ background: "white" }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div><img src="logo.png" alt="Leafy Logo" width="120px" /></div>
                                <div className='bookingHead3' style={{ color: "rgba(33, 136, 33, 1)" }}>
                                    {ticketInfo?.bus_type?.split(",")[1]}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <div className="bookingHead1">From</div>
                                <div className="bookingHead1">To</div>
                            </div>

                            <div className='row'>
                                <div className="col-md-3 col-4 mt-3">
                                    <div className="d-flex flex-column">
                                        <div className="bookingHead4">{ticketInfo?.origin}</div>
                                        <div className="bookingHead5">{ticketInfo?.boarding_details?.address}</div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-4 mt-4">
                                    <div className="d-flex flex-column">
                                        <div className="dashBorder2 mt-1">
                                            <div className="line2"></div>
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    background: "white",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <RiBusWifiLine color="black" size={30} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-4 mt-3 text-end">
                                    <div className="d-flex flex-column">
                                        <div className="bookingHead4">{ticketInfo?.destination}</div>
                                        <div className="bookingHead5">{ticketInfo?.drop_off_details?.address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center bookingHead3 mt-2'>
                                {`Ticket Number: ${ticketInfo?.ticket_number}`}
                            </div>
                            <div className="mt-md-4 mt-3">
                                <table className="w-100">
                                    <tbody>
                                        {ticketInfo?.passenger_details?.map((item) => {
                                            return (
                                                <tr className="d-flex w-100 mt-2">
                                                    <td className="flex-fill bookingHead3">{item?.name}</td>
                                                    <td
                                                        className="flex-fill bookingHead3"
                                                        style={{ color: "rgba(96, 96, 96, 1)" }}
                                                    >
                                                        {`${item?.age}Yrs`}
                                                    </td>
                                                    <td
                                                        className="flex-fill bookingHead3"
                                                        style={{ color: "rgba(96, 96, 96, 1)" }}
                                                    >
                                                        {item?.gender === "M" ? "Male" : "Female"}
                                                    </td>
                                                    <td
                                                        className="flex-fill bookingHead3 text-end"
                                                        style={{ color: "rgba(33, 136, 33, 1)" }}
                                                    >
                                                        {`Seat No.${item?.seat_number}`}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 pageBorder2 '>
                            <div className="gap-5 mt-3 mb-md-0 mb-3">
                                <div className="p-md-4 p-3 bg-white d-flex align-items-center justify-content-center">
                                    {passengerQR({ passengers })}
                                </div>
                                <div className="d-flex flex-column justify-content-between mb-1 ">
                                    <div className='text-center'>
                                        {/* <button className="btn btn-ticket w-75">Book Another Ticket</button> */}
                                        <button className="btn btn-secondary mt-3 w-75" onClick={handleDownloadTicket}>
                                            Download Ticket
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-4 container">
                    <Connect title={"Request A Call Back !"} />
                </div>
            </div>
            <div className='container' style={{ display: "none" }}>
                <PdfUI
                    ref={pdfContentRef}
                    ticketInfo={ticketInfo}
                    totalTax={totalTax}
                    totalTicket={totalTicket}
                    totalDiscount={totalDiscount}
                />
            </div>
        </React.Fragment>
    );
}
export default ConfirmationPage


// function to generate the qr code for the booking with particular passenger-----
export const passengerQR = ({ passengers }) => {
    if (!Array.isArray(passengers) || passengers?.length === 0) {
        console.error("Invalid passenger data:", passengers);
        return <div>No valid passenger data available for QR code.</div>;
    }

    const passengerInfo = JSON.stringify(passengers);

    return (
        <div
            style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 150,
                width: "100%",
                background: "white",
            }}
        >
            <QRCode
                size={200}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={passengerInfo} // Use the passenger info as the QR code value
                viewBox={`0 0 200 200`}
            />
        </div>
    );
};