import React, { useEffect, useState } from "react";
import FeaturesSlider from "../../components/FeaturesSlider";
import DateSlider from "../../components/DateSlider";
import BusCard from "../../components/BusCard";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getRoutes } from "../../services/allApi";
import toast from "react-hot-toast";
import { format } from "date-fns";
import Loader from "../../components/Loader/Loader";
import { useDataContext } from "../../services/DataContext";
import { Spinner } from "reactstrap";
import DropBoard from "./DropBoard";

const Bookings = () => {
  // the setting's for the slick carousel moving -----
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };

  const { uniqueLoc } = useDataContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [dest, setDest] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [busData, setBusData] = useState([]);
  const [check, setCheck] = useState({});
  const [selectedBus, setSelectedBus] = useState(null);

  // Function to fetch the routes of the buses  through the useEffect=----
  const fetchData = async (srcID, destID, travelDate) => {
    try {
      setIsLoading(true);
      let id1 = srcID;
      let id2 = destID;
      let date = travelDate;
      const response = await getRoutes(id1, id2, date);
      if (response?.data?.code === 402) {
        setBusData([]);
        setIsLoading(false);
      }
      else {
        setCheck(false)
        const busData = response?.data;
        setBusData(response?.data);
        navigate(`/booking-leafybus?Origin=${busData[0]?.origin}&srcID=${busData[0]?.origin_id}&destination=${busData[0]?.destination}&destID=${busData[0]?.destination_id}&travelDate=${travelDate}`, { state: { busData: busData } });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  // Fetch bus data when URL parameters change
  useEffect(() => {
    const { srcID, destID, travelDate } = Object.fromEntries(searchParams.entries());
    if (location.state?.busData) {
      setBusData(location.state.busData || []);
    }
    else if (srcID && destID && travelDate) {
      setOrigin(srcID);
      setDest(destID);
      setSelectedDate(travelDate);
      fetchData(srcID, destID, travelDate)
    } else {
      setOrigin("");
      setDest("");
      setSelectedDate("");
      setBusData([]);
    }
  }, [searchParams]);

  // Function to handle the origin----
  const handleOrigin = (e) => {
    const res = e.target.value;
    if (res !== dest) {
      setOrigin(res);
    } else {
      toast.error("Origin can't be same as Destination");
      return;
    }
  };

  // function to handle the destination--
  const handleDest = (e) => {
    const res = e.target.value;
    if (res !== origin) {
      setDest(res);
    } else {
      toast.error("Destination can't be same as Origin");
      return;
    }
  };

  //  Callback to get the date data from the date sider components-------
  const handleDate = (data) => {
    setSelectedDate(format(data, "yyyy-MM-dd"));
  };

  // Function to get the list of the buses on a specific route and date---
  const handleRoute = async (e) => {
    e.preventDefault();
    try {
      setIsLoading1(true);
      let id1 = origin;
      let id2 = dest;
      let date = selectedDate;
      const response = await getRoutes(id1, id2, date);
      if (response?.data?.code === 402) {
        setBusData([]);
        setIsLoading1(false);
      }
      else {
        setCheck(false)
        const busData = response?.data;
        setBusData(response?.data);
        navigate(`/booking-leafybus?Origin=${busData[0].origin}&srcID=${origin}&destination=${busData[0].destination}&destID=${dest}&travelDate=${selectedDate}`, { state: { busData: busData } });
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
      console.log(error);
    }
  };

  // Function to update the checkstate----
  useEffect(() => {
    const now = new Date();
    const currentDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()}`;
    const currentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

    const busCheck = busData?.map((item) => {
      const busDate = new Date(item?.dep_date.split('/').reverse().join('-'));
      const currentDateObj = new Date(`${currentDate.split('/').reverse().join('-')}T00:00:00`);
      if (busDate < currentDateObj) {
        setCheck((prev) => ({
          ...prev,
          [item?.id]: true,
        }));
        return;
      }
      else if (item?.dep_date === currentDate) {
        const [busHours, busMinutes] = item?.dep_time.split(':').map(Number);
        const [currentHours, currentMinutes] = currentTime.split(':').map(Number);
        const busTimeInMinutes = busHours * 60 + busMinutes;
        const currentTimeInMinutes = currentHours * 60 + currentMinutes;
        if (busTimeInMinutes < currentTimeInMinutes) {
          setCheck((prev) => ({
            ...prev,
            [item?.id]: true,
          }));
          return;
        }
      }
    })
  }, [busData])


  // Function to handle the bus click and its data
  const busClick = (bus) => {
    const travel = bus?.dep_date;
    const [day, month, year] = travel?.split("/");
    const formattedDate = `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
    if (!check.hasOwnProperty(bus?.id) || check[bus?.id] !== true) {
      setSelectedBus(bus);
      // navigate(
      //   `/drop-off-leafybus?${bus?.origin.toLowerCase().replace(/\s+/g, '-')}-to-${bus?.destination?.toLowerCase().replace(/\s+/g, '-')}&travelDate=${formattedDate}`,
      //   { state: { bus } }
      // );
    }
  };

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : ""}
      <div className="contentSection">
        <div className="bookingBanner mt-0">
          <div className="row">
            <div className="col-md-6 col-6"></div>
            <div className="col-md-6 col-6">
              <div className="p-3 d-flex flex-column">
                <div className="">Bus Booking</div>
                <div className="contactText">Book Your Leafy Bus</div>
                <div className="contactText">Now</div>
              </div>
            </div>
          </div>
        </div>
        {selectedBus ? <DropBoard busData={selectedBus} /> :
          <React.Fragment>
            <div className="container mt-md-5 mt-3 mb-5">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-12  order-2 order-md-1 mt-md-0 mt-5">
                  <form onSubmit={handleRoute}>
                    <div className="d-flex align-items justify-content-between gap-3">
                      <select
                        className="form-select py-md-3 py-2  w-100  pointer"
                        style={{ backgroundColor: "rgba(232, 232, 232, 1)", boxShadow: "none" }}
                        required

                        onChange={handleOrigin}
                        value={origin}
                      >
                        <option value="" disabled >
                          Source Location
                        </option>
                        {uniqueLoc?.map((item, index) => {
                          return (
                            <option
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className="form-select py-md-3 py-2  w-100 "
                        style={{ backgroundColor: "rgba(232, 232, 232, 1)", cursor: "pointer", boxShadow: "none" }}
                        required
                        onChange={handleDest}
                        value={dest}
                      >
                        <option value="" disabled >
                          Destination
                        </option>
                        {uniqueLoc?.map((item, index) => {
                          return (
                            <option key={item?.id} value={item?.id}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mt-3 w-100">
                      <DateSlider onDateChange={handleDate} />
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-secondary w-100" type="submit" disabled={isLoading1}>
                        {isLoading1 ? <Spinner size="sm" color="light" /> : "Search For Buses"}
                      </button>
                    </div>
                  </form>
                  <div className="mt-3">
                    <FeaturesSlider settings={settings} />
                  </div>
                  <div className="mt-3">
                    <div className="text-start">
                      Discover a seamless way to plan and book your bus with us.
                      Whether you're traveling across the city or exploring new
                      destinations, our platform offers a user-friendly experience
                      designed to make your travel booking easy, fast, and secure.
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-md-0 col-0 order-md-2"></div>
                <div className="col-lg-7 col-md-12 col-12 order-1 order-md-3">
                  {busData != null && busData?.length > 0 ? <React.Fragment>
                    {busData?.map((item, index) => {
                      return (
                        <div
                          className={`d-flex flex-column ${index === 0 ? 'mt-0' : 'mt-3'}`}
                          key={item?.id}
                          onClick={() => busClick(item)}
                        >
                          <BusCard data={item} check={!!check[item?.id]} />
                        </div>
                      );
                    })}
                  </React.Fragment> :
                    <div className="d-flex flex-column align-items-center justify-content-center p-md-5 p-4 mt-md-5 mt-3">
                      <div className="text-center mt-5 noBusText">
                        Sorry, No Leafy buses are available on this day.
                      </div>
                    </div>
                  }

                </div>
              </div>
              {/* <div className="mt-5 mb-2">
            <Connect title={"Request A Call Back !"} />
          </div> */}
            </div>
          </React.Fragment>
        }

        {/* <div className="mt-5 mb-5">
          <Testimonial />
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Bookings;
