import React, { useEffect, useState } from "react";
import { IoMdBus } from "react-icons/io";
import GalleryImage from "./GalleryImage";
import AppScreen from "./AppScreen";
import Destinations from "./Destinations";
import Marquee from "./Marquee";
import Features from "./Features";
import Testimonial from "./Testimonial";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ErrorModal";



const HomePage = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (location.state?.showModal) {
      setIsOpen(true);
      setMessage(location.state.message);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);


  return (
    <React.Fragment>
      {isOpen && <ErrorModal isOpen={isOpen} setIsOpen={setIsOpen} message={message} />}
      <Features />
      <div className="pb-5" style={{ background: "#F2FEF3" }}>
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-6 d-flex flex-column">
              <p style={{ fontWeight: 600 }}>PROMISE FOR HER</p>
              <h3 style={{ fontWeight: 600 }}>Book Your Journey with Ease</h3>
              <img
                src="/images/promise2.png"
                className="img-fluid"
                alt="Promise"
                style={{ borderRadius: "12px" }}
              />
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mt-lg-0 mt-md-5 mt-sm-3 mt-4 rounded-md">
              <img
                src="/images/promise1.png"
                className="img-fluid"
                alt="Promise"
                style={{ borderRadius: "12px" }}
              />
            </div>
          </div>
          <div className="row">
            <div className=" col-md-4 col-sm-6 col-12 d-flex flex-column">
              <div className="d-flex align-items-center">
                <IoMdBus />
                <h5 className="ml-2">A Seat Just for You</h5>
              </div>
              <div>
                <p style={{ fontWeight: 400, fontSize: "16px" }}>
                  Choose your ideal seat, ensuring your journey is as
                  comfortable as it is stylish.
                </p>
              </div>
            </div>
            <div className=" col-md-4 col-sm-6 col-12 d-flex flex-column">
              <div className="d-flex align-items-center">
                <IoMdBus />
                <h5 className="ml-2">Your Luggage, Our Care</h5>
              </div>
              <div>
                <p style={{ fontWeight: 400, fontSize: "16px" }}>
                  Get help with check-in, storage, and retrieval of your bags.
                </p>
              </div>
            </div>
            <div className=" col-md-4 col-sm-6 col-12 d-flex flex-column">
              <div className="d-flex align-items-center">
                <IoMdBus />
                <h5 className="ml-2">A Seat Just for You</h5>
              </div>
              <div>
                <p style={{ fontWeight: 400, fontSize: "16px" }}>
                  Choose your ideal seat, ensuring your journey is as
                  comfortable as it is stylish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="environmentBanner">
        <div>
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="d-flex flex-column gap-2">
                  <div>
                    <p className="">ECO-FRIENDLY IMPACT</p>
                  </div>
                  <div>
                    <h2 className="text-md-start">HELP SAVE CARBON AND</h2>
                    <h2 className="text-md-start">BENEFIT THE ENVIRONMENT</h2>
                  </div>
                  <div className="mt-4">
                    <ul style={{ fontWeight: 400 }}>
                      <li className="mb-2">Zero Tailpipe Emissions</li>
                      <li className="mb-2">Improved Urban Air Quality</li>
                      <li className="mb-2">Reduced Greenhouse Gas Emissions</li>
                      <li>Quiet Operation</li>
                    </ul>
                  </div>
                  <div>
                    {/* <div className="row md-mt-5 mt-0 align-items-center text-md-start">
                      <div className="col-md-7 col-sm-7 col-6">
                        <h1
                          style={{
                            color: "#17FF17",
                            fontWeight: 700,
                          }}
                        >
                          54 Kg
                        </h1>
                      </div>
                      <div className="col-md-5 col-sm-5 col-6">
                        <h2
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Carbon Saved
                        </h2>
                        <h2
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          By Leafy Bus
                        </h2>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-lg-0 mt-4 text-center">
                <div className="d-flex align-items-center h-100">
                  <img
                    src="/images/pie.png"
                    alt="piechart"
                    className=""
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-1 text-center">
            <h2>TRAVEL WITH US AND MAKE A POSITIVE IMPACT</h2>
          </div>
        </div>
      </div>
      {/* <GalleryImage /> */}

      <div className="mt-5">
        <AppScreen />
      </div>
      <Destinations />
      {/* <Testimonial /> */}
      {/* <Marquee /> */}



    </React.Fragment>
  );
};

export default HomePage;
