import React, { useEffect, useState } from "react";
import BusCard from "../../components/BusCard";
import { useNavigate } from "react-router-dom";

const DropBoard = ({ busData }) => {
    const navigate = useNavigate();
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [selectedDrop, setSelectedDrop] = useState(null);
    const [boardPoint, setBoardingPoint] = useState([]);
    const [dropPoint, setDropPoint] = useState([]);
    const [shakeBoard, setShakeBoard] = useState(false);
    const [shakeDrop, setShakeDrop] = useState(false);


    // useEfect Function to get page to the top--
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     const middlePosition = document.body.scrollHeight / 2;
    //     window.scrollTo({
    //         top: middlePosition,
    //         behavior: 'smooth',
    //     });
    // }, []);


    // Function to set drop/board points
    useEffect(() => {
        setDropPoint(busData?.drop_off_details);
        setBoardingPoint(busData?.boarding_point_details);
    }, [busData]);

    // Function to handle baord/drop
    const handleBoard = (point) => {
        setSelectedPoint((prev) => (prev === point ? null : point));
        setShakeBoard(false);
    };
    const handleDrop = (point) => {
        setSelectedDrop((prev) => (prev === point ? null : point));
        setShakeDrop(false);
    };

    // Function to handle the drop&board points--
    const handleBooking = () => {
        if (!selectedPoint) {
            setShakeBoard(false);
            setTimeout(() => setShakeBoard(true), 0);
            return
        }
        if (!selectedDrop) {
            setShakeDrop(false);
            setTimeout(() => setShakeDrop(true), 0);
            return
        }
        if (selectedDrop && selectedPoint) {
            navigate(
                `/passenger-leafybus?selectedDrop=${selectedDrop?.id}&selectedPoint=${selectedPoint?.id}&from=${busData?.origin}&to=${busData?.destination}&drop=${selectedDrop?.name}$board=${selectedPoint?.name}&reservationID=${busData?.reservation_id}&destID=${busData?.destination_id}&originID=${busData?.origin_id}`,
                { state: { busData } }
            );
        }
    };


    return (
        <React.Fragment>
            <div className="contentSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12  pb-lg-0 pb-md-5 pb-5">
                            <BusCard data={busData} />
                        </div>
                        <div className="col-lg-1 col-md-0 col-0"></div>
                        <div className="col-lg-5 col-md-12 col-12 mb-5">
                            <>
                                <div className="dropLables">Select Boarding Point</div>
                                <div
                                    className={`d-flex justify-content-between flex-wrap mt-3 gap-xl-3 gap-lg-2 gap-md-3 gap-sm-3 gap-2 ${shakeBoard ? "shake" : ""}`}
                                >
                                    {boardPoint?.map((point, index) => (
                                        <div
                                            key={index}
                                            className={`dropCard ${selectedPoint === point ? "selected" : ""}`}
                                            onClick={() => handleBoard(point)}
                                        >
                                            {point?.name}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-3 dropLables">Select Dropping Point</div>
                                <div
                                    className={`d-flex justify-content-between flex-wrap mt-3 gap-xl-3 gap-lg-2 gap-md-3 gap-sm-3 gap-2 ${shakeDrop ? "shake" : ""}`}
                                >
                                    {dropPoint?.map((point, index) => (
                                        <div
                                            key={index}
                                            className={`dropCard ${selectedDrop === point ? "selected" : ""}`}
                                            onClick={() => handleDrop(point)}
                                        >
                                            {point?.name}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-5">
                                    <button className="btn btn-secondary w-100" type="button" onClick={handleBooking}>
                                        Book Your Ticket
                                    </button>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default DropBoard;
