import { collection, getDocs } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { db } from "./firebaseconfig";
import { fetchCoupon, getDestinations } from "./allApi";


const DataContext = createContext();
export const DataProvider = ({ children }) => {
    const [termsPrivacyPolicyData, setTermsPrivacyPolicyData] = useState([]);
    const [couponData, setCouponData] = useState([]);
    const [uniqueLoc, setUniqueLoc] = useState([]);


    const fetchTermsPrivacyPolicyData = async () => {
        try {
            const termsPrivacyPolicyRef = collection(db, "termsPrivacyPolicy");
            const querySnapshot = await getDocs(termsPrivacyPolicyRef);
            const data = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
            setTermsPrivacyPolicyData(data);
        } catch (error) {
            console.error("Error fetching terms and privacy policy data:", error);
        }
    };


    // Function to fetch the valid coupons
    const fetchAllCoupon = async () => {
        try {
            const response = await fetchCoupon();
            const currentDateTime = new Date();
            const validCoupons = response?.filter((coupon) => {
                const expiryDateTime = coupon?.expiryDate?.toDate();
                if (expiryDateTime > currentDateTime) {
                    return true;
                }
                return false;
            });
            setCouponData(validCoupons);
        } catch (error) {
            console.error("Error fetching coupons:", error);
        }
    };

    // Function to fetch the source&dest array -----
    const fetchData = async () => {
        try {
            const response = await getDestinations();
            const locationInfo = response?.data?.flatMap((item) => [
                { id: item?.origin?.id, name: item?.origin?.name },
                { id: item?.destination?.id, name: item?.destination?.name },
            ]);
            const uniqueLocations = locationInfo?.filter(
                (location, index, self) =>
                    index === self.findIndex((loc) => loc?.id === location?.id)
            );
            setUniqueLoc(uniqueLocations);
        } catch (error) {
            console.log("Error while getting the destinations", error);
        }
    };




    useEffect(() => {
        fetchTermsPrivacyPolicyData();
        fetchAllCoupon();
        fetchData();
    }, []);

    return (
        <DataContext.Provider
            value={{
                termsPrivacyPolicyData,
                couponData, uniqueLoc
            }}>
            {children}
        </DataContext.Provider>
    )
}
export const useDataContext = () => useContext(DataContext);