import React, { useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import Otp from "./Otp";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../services/firebaseconfig";
import toast from "react-hot-toast";
import Login from "./Login";

const Signup = ({ isOpen, setIsOpen }) => {
  const [openOTP, setOpenOTP] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [err, setErr] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [openLogin, setOpenLogin] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
  });


  // Function to handle the on/off the modal signup
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle the onChange--
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "phone") {
      setErr("");
    }
  };



  // Functio to handle the OTP--
  const handleOtp = async (e) => {
    e.preventDefault();
    setErr("");
    if (!formData?.phone) {
      setErr("Mobile Number is required");
      return;
    }
    if (formData?.phone?.length < 10) {
      setErr("Mobile Number should be of 10 digits");
      return;
    }
    const phoneNumber = `+91${formData?.phone}`;
    try {
      setIsLoading(true);
      // Initialize reCAPTCHA if not already initialized
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              console.log("reCAPTCHA solved:", response);
            },
          }
        );

        // Render the reCAPTCHA
        await window.recaptchaVerifier.render();
      }
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setVerificationId(confirmationResult?.verificationId);
      setIsOtpSent(true);
      setIsOpen(false);
      setIsLoading(false);
      setOpenOTP(true);
      setTimer(60);
      setIsDisabled(false);
      setErr("");
      toast.success("OTP has been sent your Mobile Number!");
    } catch (error) {
      setIsLoading(false);
      setErr("Failed to send OTP. Please try again.");
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody>
          <form onSubmit={handleOtp}>
            <div className="row p-1">
              <div className="col-lg-5 ">
                <div className="imageHeightContainer">
                  <img src="/images/signup.png" alt="loginbus" width="100%" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="conatainer">
                  <div className="d-flex flex-column p-3">
                    <div className="authHeader">Create Account</div>
                    <div className="mt-2 authSubHeader">
                      Unlock a world of Nature with a single click! Please login
                      in to your account.
                    </div>
                    <div className="col-md-12 mt-2">
                      <label className="form-label authLabels mb-1">
                        Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        required
                        className="form-control py-2"
                        placeholder="Enter your Name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label className="form-label authLabels mb-1">
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        className="form-control py-2"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label className="form-label authLabels mb-1">
                        Mobile Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        required
                        className="form-control py-2"
                        placeholder="Enter your mobile "
                        onChange={handleChange}
                        value={formData.phone}
                        onInput={(e) => {
                          e.target.value = e.target.value?.slice(0, 10);
                        }}
                      />
                    </div>
                    {err && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {err}
                      </span>
                    )}
                    <div className="col-md-12 mt-2">
                      <label className="form-label authLabels mb-1">
                        Gender
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="gender"
                        required
                        onChange={handleChange}
                        value={formData.gender}
                      >
                        <option value="" disabled selected>
                          Select Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className="col-md-12 mt-3">
                      <button
                        type="submit"
                        className="btn btn-auth w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Generate OTP"
                        )}
                      </button>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <div>Already have an Account! </div>
                        <div
                          className="authLabels mx-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toggle();
                            setOpenLogin(true);
                          }}
                        >
                          Log in
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Otp
        setOpenOTP={setOpenOTP}
        openOTP={openOTP}
        handleOtp={handleOtp}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        verificationId={verificationId}
        formData={formData}
        setFormData={setFormData}
        timer={timer}
        setTimer={setTimer}
      />
      {openLogin ? <Login isOpen={openLogin} setIsOpen={setOpenLogin} /> : ""}
    </React.Fragment>
  );
};

export default Signup;
