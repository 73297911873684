import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { ConfettiComponent } from './ConfettiComponent'

export const CouponModal = ({ isOpen, setIsModalOpen, totalDiscount, data }) => {

    const toggle = () => {
        setIsModalOpen(false)
    }

    return (
        <div>
            {isOpen && <ConfettiComponent />}
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                centered
                size='md'
            >
                <ModalBody>
                    <div className="d-flex flex-column align-items-center justify-content-betweeen container">
                        <div className='text-center text-muted'>
                            <span style={{ color: 'darkgreen', fontWeight: 600 }}>{data?.couponName}</span> applied
                        </div>
                        <div className='mt-2'
                            style={{
                                fontSize: "20px",
                                fontWeight: 700
                            }}
                        >
                            {`₹${totalDiscount} savings with this coupon`}
                        </div>
                        <div className='mt-2'>Save every time you travel with LEAFY</div>
                        <div className='mt-3' style={{ color: 'darkgreen', fontWeight: 600, fontSize: "25px" }}>YAY!</div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
