import React, { useState } from "react";
import "../pages/HomePage/Common.css";
import InfoModal from "./InfoModal";
import { formatDateWithDay } from "../helper";

const BusCard = ({ data, check }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [infoModal, setInfoModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const stopPropagation = (e) => e.stopPropagation();
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };


  const handleClick = (item) => {
    if (check === true) {
      setInfoModal(false);
    }
    else {
      setSelectedItem(item);
      setInfoModal(true);
    }
  }




  return (
    <React.Fragment>
      {infoModal && <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        selectedItem={selectedItem}
        data={data}
      />}
      <div
        className="shadow-card"
        style={{
          borderRadius: "13px",
          backgroundColor: check ? "white" : "white",
          filter: check ? "blur(1px)" : "none",
          cursor: check ? "none" : "pointer"
        }}
      >
        <div className="p-md-3 p-2 busCard">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              Electric AC Premium (2+2)
            </div>
          </div>
        </div>
        <div className="p-md-3 p-sm-3 p-2">
          <div className="d-flex  justify-content-between align-items-center">
            <div className="">
              {formatDateWithDay(data?.dep_date)}
            </div>
            <div className="d-flex align-items-center">
              {/* <div className="bcHeader2 me-3" style={{ textDecoration: "line-through" }}>₹{data?.fare_str}</div> */}
              {/* <div className="bcHeaderCard">₹{data?.discounted_price}</div> */}
              <div className="text-muted me-3">Starting from</div>
              <div className="bcHeaderCard">₹{data?.fare_str}</div>
            </div>
          </div>

          <div className="row mt-4 align-items-center">
            <div className="col-1">
              {/* <button className="btn-eta">ETA</button> */}
            </div>
            <div className="col-11">
              <div className="row align-items-center ">
                <div className="col-2 bcHeader3">{data?.dep_time}</div>
                <div className="col-3 dashBorder">
                  <div className="line"></div>
                </div>
                <div className="col-2 text-center bcHeader1">{data?.duration}</div>
                <div className="col-3 dashBorder1">
                  <div className="line1"></div>
                </div>
                <div className="col-2 bcHeader4">{data?.arr_time}</div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="bcHeader6">
              <div className="d-flex align-items-center justify-content-between gap-1">
                <div
                  onClick={(e) => {
                    stopPropagation(e);
                    handleClick("About")
                  }}
                  onMouseEnter={() => handleMouseEnter("About")}
                  onMouseLeave={handleMouseLeave}
                  style={{ color: hoveredItem === "About" ? "rgba(4, 150, 4, 1)" : "" }}>About</div>
                <div>|</div>
                <div
                  onClick={(e) => {
                    stopPropagation(e);
                    handleClick("Amenities")
                  }}
                  onMouseEnter={() => handleMouseEnter("Amenities")}
                  onMouseLeave={handleMouseLeave}
                  style={{ color: hoveredItem === "Amenities" ? "rgba(4, 150, 4, 1)" : "" }}>Amenities</div>
                <div>|</div>
                <div
                  onClick={(e) => {
                    stopPropagation(e);
                    handleClick("Bus Route")
                  }}
                  onMouseEnter={() => handleMouseEnter("Bus Route")}
                  onMouseLeave={handleMouseLeave}
                  style={{ color: hoveredItem === "Bus Route" ? "rgba(4, 150, 4, 1)" : "" }}
                >Bus Route</div>
                <div>|</div>
                <div
                  onClick={(e) => {
                    stopPropagation(e);
                    handleClick("Policies")
                  }}
                  onMouseEnter={() => handleMouseEnter("Policies")}
                  onMouseLeave={handleMouseLeave}
                  style={{ color: hoveredItem === "Policies" ? "rgba(4, 150, 4, 1)" : "" }}>Policies</div>
              </div>
            </div>

            {check ? <div className="bcHeader5">
              Service Left
            </div> :
              <div className="bcHeader5">{data?.available_seats} Seat Left</div>
            }

          </div>
        </div>

      </div >
    </React.Fragment >

  );
};

export default BusCard;
