import React, { useEffect, useState } from "react";
import { format, addDays, subDays } from "date-fns";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

const DateSlider = ({ onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [todayDate] = useState(new Date());

  const getDates = (date) => {
    return [
      // subDays(date, 2),
      // subDays(date, 1),
      date,
      addDays(date, 1),
      addDays(date, 2),
      addDays(date, 3),
      addDays(date, 4),
      addDays(date, 5)
    ];
  };

  const handlePrevious = () => {
    if (currentDate > todayDate) {
      setCurrentDate((prevDate) => subDays(prevDate, 1));
    }
  };

  const handleNext = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  const handleDateClick = (date) => {
    setCurrentDate(date);
  };

  const dates = getDates(currentDate);
  useEffect(() => {
    onDateChange(currentDate);
  }, [currentDate]);

  return (
    <div
      className=" w-100 d-flex align-items-center justify-content-between "
      style={{
        overflowX: "hidden",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <div onClick={handlePrevious} className="pointer"
        style={{
          cursor: currentDate <= todayDate ? "not-allowed" : "pointer",
          opacity: currentDate <= todayDate ? 0.5 : 1,
          pointerEvents: currentDate <= todayDate ? "none" : "auto",
        }} >
        <FiChevronLeft color="black" size={24} />
      </div>

      <div className="d-flex align-items-center gap-lg-2 gap-md-5 gap-sm-4 gap-3 ">
        {dates?.map((date, index) => (
          <div

            key={index}
            style={{
              border: "1px solid white",
              fontSize: "14px",
              padding: "5px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor:
                date.getDate() === currentDate.getDate()
                  ? "rgba(23, 255, 23, 1)"
                  : "#ffff",
              fontWeight: date.getDate() === currentDate.getDate() ? 700 : 500,
            }}
            className={`d-flex flex-column p-1 pointer text-center ${date.getDate() === currentDate.getDate() ? "font-weight-bold" : ""
              }`}
            onClick={() => handleDateClick(date)}
          >
            <div>{format(date, "EE")}</div>
            <div>{format(date, "dd MMM")}</div>
          </div>
        ))}
      </div>

      <div onClick={handleNext} className="pointer" >
        <FiChevronRight color="black" size={24} />
      </div>
    </div>
  );
};

export default DateSlider;
