import React from 'react'
import { useDataContext } from '../../services/DataContext';
import Loader from '../../components/Loader/Loader';

export const Terms = () => {

    const { termsPrivacyPolicyData } = useDataContext();
    const data = termsPrivacyPolicyData?.map((item) => {
        if (/term/i.test(item.title)) {
            return item.content;
        } else {
            return "";
        }
    });
    return (
        <React.Fragment>
            <div className='contentSection'>
                {termsPrivacyPolicyData?.length === 0 ? <Loader /> : ""}
                <div className="container mt-4 pdHeader ">Terms and Conditions</div>
                <div className='container mt-md-5 mt-3'>
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </div>

            </div>
        </React.Fragment>
    )
}

