import React from 'react'
import { useDataContext } from '../../services/DataContext';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {

    const { termsPrivacyPolicyData } = useDataContext();
    const data = termsPrivacyPolicyData?.map((item) => {
        if (/privacy/i.test(item.title)) {
            return item.content;
        } else {
            return "";
        }
    });

    return (
        <React.Fragment>
            <Helmet>
                <title>Privacy Policy - Leafy Bus</title>
                <meta name="description" content="Read the Privacy Policy of Leafy Bus to understand how we handle and protect your data." />
                <meta name="robots" content="index, follow" />
                <meta property="og:url" content="https://www.leafybus.com/privacypolicy" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Privacy Policy - Leafy Bus" />
                <meta property="og:description" content="Read the Privacy Policy of Leafy Bus to understand how we handle and protect your data." />
                <meta name="keywords" content="Privacy Policy, Leafy Bus, Data Protection, Terms and Conditions" />
            </Helmet>
            {termsPrivacyPolicyData?.length === 0 ? <Loader /> : ""}
            <div className='contentSection mt-5'>
                <div className='container mt-md-5 mt-3'>
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </div>

            </div>
        </React.Fragment>
    )
}

export default PrivacyPolicy