import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdLocalOffer } from "react-icons/md";
import { ConfirmETicket, fetchUserData, getBookETicket, getSeatData, initiatePayment, processPaymentVerification, processTicketConfirmation } from "../../services/allApi";
import SeatBooking from "../../components/SeatBooking";
import { GiSteeringWheel } from "react-icons/gi";
import Loader from "../../components/Loader/Loader";
import { calculateFareWithGST, formatDateWithDay, handleNonSuccessStatus } from "../../helper";
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from "reactstrap";
import CouponCard from "../../components/CouponCard";
import { useDataContext } from "../../services/DataContext";
import toast from "react-hot-toast";
import BillDetail from "../../components/BillDetail";
// import SeatType from "../../components/SeatType";
import { AddErrorData } from "../../services/UserExistence";


const PassengerDetails = () => {
  let paymentURL = "";
  const navigate = useNavigate();
  const { couponData } = useDataContext();
  const [searchParams] = useSearchParams();
  const selectedDrop = searchParams.get('selectedDrop');
  const selectedPoint = searchParams.get('selectedPoint');
  const originSourceID = searchParams.get('originID');
  const destinationID = searchParams.get('destID');
  const reservationID = searchParams.get('reservationID');
  const [gstPercent, setGstPercent] = useState("");
  const [busDataInfo, setBusDataInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [seatInfo, setSeatInfo] = useState();
  const [passengerDetail, setPassengerDetail] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [couponValue, setCouponValue] = useState([]);
  const [userInfo, setUserInfo] = useState({
    name: "",
    age: "",
    sex: "",
  })
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [mobileErrorMsg, setMobileErrorMsg] = useState("");
  const userID = localStorage.getItem("userID");
  const [loggedUser, setLoggedUser] = useState({
    name: "",
    email: "",
    phone: ""
  })
  const [seatLayout, setSeatLayout] = useState([]);


  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // useEfect Function to get page to the top--
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // function to fetch the logged user data---
  useEffect(() => {
    const fetchUserDataAsync = async () => {
      try {
        const response = await fetchUserData(userID);
        setLoggedUser((prev) => ({
          ...prev,
          name: response.name,
          email: response.email,
          phone: response.phone,
        }));
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };

    if (userID) {
      fetchUserDataAsync();
    }
  }, [userID]);

  // function to fetch Seat Data--
  const fetchSeatData = async () => {
    try {
      setIsLoader(true);
      let reservID = reservationID;
      let boardID = selectedPoint;
      let dropID = selectedDrop;
      let originID = originSourceID;
      let destID = destinationID;
      const response = await getSeatData(reservID, originID, destID, boardID, dropID);
      setSeatLayout(response?.data?.coach_details?.seat_details)
      setSeatInfo(response?.data);
      setBusDataInfo(response?.data);
      setGstPercent(response?.data?.st_percent)
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error, "Error log");
    }
  }

  useEffect(() => {
    fetchSeatData();
  }, [])
  const handleSeatData = (data) => {
    setSeatData(data);
  };




  // Function to handle the Passenger Details
  const handlePassengerChange = (index, key, value) => {
    if (key === "sex") {
      const seat = seatData[index];
      // const match = seat?.seat?.match(/^(\d+)([A-E])$/);
      // if (!match) {
      //   console.error("Invalid seat format:", seat.seat);
      //   return;
      // }
      // const seatNumber = parseInt(match[1], 10);
      // const serial = match[2];
      // const adjacentSeatMap = {
      //   B: "A",
      //   A: "B",
      //   C: "D",
      //   D: "C",
      //   E: "D",
      // };

      // if (seatNumber === 12) {
      //   if (serial === "B") {
      //     const checks = [seatNumber + "A", seatNumber + "C"];
      //     for (const check of checks) {
      //       const response = seatLayout?.find((item) => item?.number === check);
      //       if (response?.is_ladies_seat === true && value === "Mr") {
      //         toast.error("You cannot book a female-only seat for a male passenger.");
      //         return;
      //       }
      //     }
      //   } else if (serial === "C") {
      //     const checks = [seatNumber + "B", seatNumber + "D"];
      //     for (const check of checks) {
      //       const response = seatLayout?.find((item) => item?.number === check);
      //       if (response?.is_ladies_seat === true && value === "Mr") {
      //         toast.error("You cannot book a female-only seat for a male passenger.");
      //         return;
      //       }
      //     }
      //   } else if (serial === "D") {
      //     const checks = [seatNumber + "C", seatNumber + "E"];
      //     for (const check of checks) {
      //       const response = seatLayout?.find((item) => item?.number === check);
      //       if (response?.is_ladies_seat === true && value === "Mr") {
      //         toast.error("You cannot book a female-only seat for a male passenger.");
      //         return;
      //       }
      //     }
      //   } else if (serial === "E") {
      //     const check = seatNumber + "D";
      //     const response = seatLayout?.find((item) => item?.number === check);
      //     if (response?.is_ladies_seat === true && value === "Mr") {
      //       toast.error("You cannot book a female-only seat for a male passenger.");
      //       return;
      //     }
      //   }
      // } else {
      //   if (adjacentSeatMap[serial]) {
      //     const check = seatNumber + adjacentSeatMap[serial];
      //     const response = seatLayout?.find((item) => item?.number === check);
      //     if (response?.is_ladies_seat === true && value === "Mr") {
      //       toast.error("You cannot book a female-only seat for a male passenger.");
      //       return;
      //     }
      //   }
      // }

      if (seat?.female_seat === true && value === "Mr") {
        toast.error("You cannot book a female-only seat for a male passenger.");
        return;
      }
    }

    // Update passenger details if validation passes
    setPassengerDetail((prevDetails) =>
      prevDetails?.map((detail, i) =>
        i === index ? { ...detail, [key]: value } : detail
      )
    );

    // Clear errors for the updated field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: { ...prevErrors[index], [key]: "" },
    }));
  };


  useEffect(() => {
    const updatedPassengerDetails = seatData?.map((item, index) => ({
      is_primary: index === 0,
      seat_number: item.seat,
      fare: item?.fare,
      max_fare: item?.max_fare,
      min_fare: item?.min_fare,
      sex: userInfo?.sex,
      name: userInfo?.name,
      age: userInfo?.age,
      is_round_trip_seat: false,
      id_card_type: "2",
      id_card_number: "null",
      passport_issued_date: "",
      passport_expiry_date: "",
      place_of_issue: "",
    }));
    setPassengerDetail(updatedPassengerDetails || []);
  }, [seatData, userInfo]);

  const handleCouponSelect = (selectedCoupon) => {
    setCouponValue(selectedCoupon);
  };

  useEffect(() => {
    if (passengerDetail?.length === 0) {
      setCouponValue([]);
    }
  }, [passengerDetail])










  // Function for the payment gatewayyy---
  const handleTicketBooking = async () => {
    if (!loggedUser?.phone) {
      setMobileError(true);
      return
    }
    if (!loggedUser?.email) {
      setEmailError(true);
      return
    }
    let errorOccurred = false;
    const tempErrors = {};
    passengerDetail?.forEach((detail, index) => {
      const passengerErrors = {};
      if (!detail?.name) {
        errorOccurred = true;
        passengerErrors.name = 'Name is required';
      }
      if (!detail?.age) {
        errorOccurred = true;
        passengerErrors.age = 'Age is required';
      }
      if (!detail?.sex) {
        errorOccurred = true;
        passengerErrors.sex = 'Gender is required';
      }
      if (Object.keys(passengerErrors)?.length > 0) {
        tempErrors[index] = passengerErrors;
      }
    });

    if (errorOccurred) {
      setErrors(tempErrors);
      return;
    }
    try {
      let { totalTicket } = calculateFareWithGST(passengerDetail, couponValue, gstPercent);
      let txnid = uuidv4();
      setIsLoading(true);
      let reservID = reservationID;
      let boardID = selectedPoint;
      let dropID = selectedDrop;
      let originID = originSourceID;
      let destID = destinationID;
      let seatCount = passengerDetail?.length
      let payload = {
        "seat_details": passengerDetail,
        "contact_detail": {
          "emergency_name": loggedUser?.name || "",
          "mobile_number": loggedUser?.phone,
          "email": loggedUser?.email,
          "country_code": "+91"
        },
        "coupon_details": [],
        "passenger_gst_details": {},
        "offer_coupon_details": [],
        "smart_miles_details": []
      }
      const response = await getBookETicket(reservID, originID, destID, boardID, dropID, seatCount, payload);
      if (response?.status === 200 && response?.data?.ticket_number) {
        let ticketNumber = response?.data?.ticket_number;
        let payload1 = {
          "txnid": txnid,
          "amount": totalTicket,
          "productinfo": "Leafy Bus Ticket",
          "firstname": passengerDetail[0].name.trim(),
          "email": loggedUser.email.trim(),
          "phone": loggedUser.phone.trim(),
          "surl": "https://www.leafybus.com",
          "furl": "https://www.leafybus.com",
        }
        const response2 = await initiatePayment(payload1);
        if (response2?.data?.status === "success") {
          paymentURL = response2?.data?.data?.payment_url;
          if (paymentURL) {
            if (typeof window.EasebuzzCheckout === 'undefined') {
              navigate("/", { state: { showModal: true, message: "Payment initiation failed! Please try again" } });
              console.error('EasebuzzCheckout script is not loaded');
              return;
            }
            const easebuzzCheckout = new window.EasebuzzCheckout('L64KQKDYX2', 'prod');
            const options = {
              access_key: paymentURL,
              onResponse: async (response) => {
                const { txnid, amount, email, phone, status } = response;
                if (status !== "success") {
                  handleNonSuccessStatus(status, navigate);
                  return;
                }
                setIsLoader(true);
                const verifyPayload = { txnid, amount, email, phone };
                try {
                  const verificationResponse = await processPaymentVerification(verifyPayload);
                  if (!verificationResponse || verificationResponse?.data?.status !== 'success') {
                    setIsLoader(false)
                    await AddErrorData(verificationResponse?.data, "verification");
                    navigate("/", { state: { showModal: true, message: "Payment could not be verified! Please contact our support team!" } });
                    return;
                  }

                  // Confirm the e-ticket
                  const ticketResponse = await ConfirmETicket(ticketNumber);
                  if (!ticketResponse || ticketResponse?.data?.status !== "success") {
                    setIsLoader(false);
                    await AddErrorData(ticketResponse?.data, "confirmTicketDataError");
                    navigate("/", { state: { showModal: true, message: "An error while confirming the ticket! Please contact our support team!" } });
                    return;
                  }

                  // Get transaction details from verification response
                  const easeBuzzTransactionId = verificationResponse?.data?.transactionDetails?.easepayid;
                  let ticketInfo = ticketResponse?.data?.ticket_details;

                  // Process ticket confirmation
                  const ticketProcessed = await processTicketConfirmation(ticketInfo, txnid, easeBuzzTransactionId);
                  if (!ticketProcessed) return;
                  navigate("/confirmationbooking-leafybus", {
                    state: {
                      ticketInfo,
                      txnid,
                      totalTax,
                      totalTicket,
                      totalDiscount,
                      easeBuzzTransactionId,
                    },
                  });
                } catch (error) {
                  setIsLoader(false);
                  await AddErrorData(error, "process");
                  navigate("/", { state: { showModal: true, message: "Payment could not be processed! Please contact us at +91- 8957097521 or at +91- 9911215692!" } });
                }
              },
              theme: '#123456',
            }
            easebuzzCheckout.initiatePayment(options);
          }
        }
        else if (response2?.data?.status === "failure") {
          navigate("/", { state: { showModal: true, message: "Payment initiation failed! Please try again" } });
        }
      }
      else if (response?.status === 200 && response?.data?.code) {
        setIsLoading(false);
        navigate("/", { state: { showModal: true, message: response?.data?.message } });
      }

    } catch (error) {
      setIsLoading(false);
      navigate("/", { state: { showModal: true, message: "An error occured! Please try again" } });
    }
  }




  const { totalTicket, totalTax, totalDiscount } = calculateFareWithGST(passengerDetail, couponValue, gstPercent);


  return (
    <React.Fragment>
      {isLoader ? <Loader /> :
        ""
      }
      {paymentURL && (
        <iframe
          id="payment-iframe"
          width="100%"
          color="black"
          src={paymentURL}
          title="Easebuzz Payment"
        />
      )}
      <div className="contentSection">
        <div className="container mt-3 pdHeader ">Review Your Booking</div>
        <div className="container  mt-md-4 mt-2 mb-5">
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="d-md-flex justify-content-center  p-md-0 p-2">
                <div className="col-md-5 col-12 ms-md-0 ms-0 h-100 text-center"
                  style={{
                    border: "3px solid #D3D3D3",
                    borderRadius: "25px",
                    background: "rgb(250, 250, 250)"
                  }}>
                  <div className="d-flex flex-column  align-items-end  p-3">
                    <GiSteeringWheel size={32} color="#D3D3D3" />
                    <div style={{
                      width: "32px",
                      borderBottom: "2px solid #D3D3D3",
                      marginTop: "4px",
                    }}></div>
                  </div>
                  <div className="mb-3">
                    <SeatBooking
                      maxRow={seatInfo?.coach_details?.no_of_rows}
                      maxCol={seatInfo?.coach_details?.no_of_cols}
                      data={seatInfo?.coach_details?.seat_details}
                      onSeatDataChange={handleSeatData}
                    />
                  </div>
                </div>
                {
                  passengerDetail?.length > 0 ? <div className="col-md-7 col-12 ms-2 mt-md-1 mt-3 p-2 noScroll passengerCard">
                    {passengerDetail?.length > 0 ?
                      <>
                        <h5 className="text-center mt-2">Your Contact Details</h5>
                        <div className="row p-3">
                          <div className=""
                            style={{
                              border: "2px dashed #D3D3D3",
                              borderRadius: "10px"
                            }}>
                            <form>
                              <div className="col-12 mt-3">
                                <input
                                  style={{
                                    boxShadow: "none",
                                    fontSize: "14px",
                                    border: mobileError ? "1px solid red" : "",
                                  }}
                                  type="number"
                                  className="form-control bg-gray"
                                  placeholder="Mobile Number"
                                  id="phone"
                                  name="phone"
                                  required
                                  value={
                                    loggedUser?.phone
                                      ? loggedUser?.phone.startsWith("+91")
                                        ? loggedUser?.phone.slice(3)
                                        : loggedUser?.phone
                                      : ""
                                  }
                                  onInput={(e) => {
                                    e.target.value = e.target.value.slice(0, 10)
                                  }}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setLoggedUser((prev) => ({
                                      ...prev,
                                      phone: newValue?.startsWith("+91")
                                        ? newValue
                                        : `+91${newValue}`,
                                    }));
                                    if (newValue.length < 10) {
                                      setMobileErrorMsg("Please enter a valid 10-digit mobile number");
                                      return
                                    }
                                    else {
                                      setMobileErrorMsg("");
                                    }
                                    setMobileError(false);
                                  }}
                                />
                                {mobileErrorMsg ? <span style={{ fontSize: "10px" }} className="text-danger">{mobileErrorMsg}</span> : ""}
                              </div>
                              <div className="col-12 mb-3 mt-3">
                                <input
                                  style={{
                                    fontSize: "14px",
                                    boxShadow: "none",
                                    border: emailError ? "1px solid red" : "",
                                  }}
                                  type="email"
                                  className="form-control bg-gray"
                                  placeholder="Email"
                                  id="email"
                                  name="email"
                                  required
                                  value={loggedUser?.email || ""}
                                  onChange={(e) => {
                                    setLoggedUser((prev) => ({
                                      ...prev,
                                      email: e.target.value
                                    }))
                                    let email = e.target.value;
                                    if (!emailRegex.test(email)) {
                                      setEmailErrorMsg("Please enter a valid email address.");
                                      return
                                    } else {
                                      setEmailErrorMsg("");
                                    }
                                    setEmailError(false)
                                  }}
                                />
                                {emailErrorMsg ? <span style={{ fontSize: "10px" }} className="text-danger">{emailErrorMsg}</span> : ""}
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                      : ""}
                    {passengerDetail?.length > 0 ? <h4 className="text-center mt-3">Selected Seats</h4> : ""}
                    {passengerDetail &&
                      passengerDetail?.length > 0 ? <div>
                      {passengerDetail && passengerDetail?.map((item, index) => {
                        return (
                          <div key={index} className="row p-1 mt-3 align-items-stretch">
                            <div className="col-4">
                              <div style={{
                                border: "2px dashed #D3D3D3",
                                borderLeft: "2px dashed #D3D3D3",
                                borderRight: "2px dashed #D3D3D3",
                                borderTop: "2px dashed #D3D3D3",
                                borderBottom: "2px dashed #D3D3D3",
                                borderRadius: "10px",
                                height: "100%",
                                position: "relative",
                                background: "#ECF7F6"
                              }} className="d-flex flex-column align-items-center justify-content-center p-1">

                                <div className=""
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600
                                  }}>Seat:{item?.seat_number}</div>
                                <div className="mt-4 p-1"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600
                                  }}>₹{item?.fare}</div>
                                <div style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '-10px',
                                  width: '20px',
                                  height: '20px',
                                  backgroundColor: 'white',
                                  borderRadius: '50%',
                                  transform: 'translateY(-50%)',
                                }} />
                              </div>
                            </div>
                            <div className="col-7 ms-1 p-2"
                              style={{
                                border: "2px dashed #D3D3D3",
                                borderLeft: "2px dashed #D3D3D3",
                                borderRight: "2px dashed #D3D3D3",
                                borderTop: "2px dashed #D3D3D3",
                                borderBottom: "2px dashed #D3D3D3",
                                borderRadius: "10px",
                                height: "100%",
                                position: "relative",

                              }}
                            >
                              <form>
                                <div>
                                  <input
                                    style={{
                                      border: "none",
                                      borderBottom: `1px solid ${errors[index]?.name ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                      borderRadius: "0",
                                      boxShadow: "none",
                                      fontSize: "15px"
                                    }}
                                    type="text"
                                    className="form-control bg-gray"
                                    placeholder="Name"
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => {
                                      handlePassengerChange(index, "name", e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="d-flex mt-2">
                                  <div className="col-4">
                                    <input
                                      style={{
                                        border: "none",
                                        borderBottom: `1px solid ${errors[index]?.age ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                        borderRadius: "0",
                                        boxShadow: "none",
                                        fontSize: "15px"
                                      }}
                                      type="number"
                                      className="form-control bg-gray"
                                      placeholder="Age"
                                      id="age"
                                      name="age"
                                      required
                                      onInput={(e) => {
                                        e.target.value = e.target.value.slice(0, 3)
                                      }}
                                      onChange={(e) => {
                                        handlePassengerChange(index, "age", e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-8 ms-1">
                                    <select
                                      className="form-select"
                                      style={{
                                        border: "none",
                                        borderBottom: `1px solid ${errors[index]?.sex ? "red" : "rgba(128, 128, 128, 0.5)"}`,
                                        borderRadius: "0",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                        fontSize: "15px"
                                      }}
                                      id="sex"
                                      name="sex"
                                      required
                                      value={passengerDetail[index]?.sex || ""}
                                      onChange={(e) => {
                                        handlePassengerChange(index, "sex", e.target.value)
                                      }}
                                    >
                                      <option value="" selected>
                                        Gender
                                      </option>
                                      <option value="Mr">Male</option>
                                      <option value="Ms">Female</option>
                                    </select>
                                  </div>
                                </div>
                              </form>
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                right: '-10px',
                                width: '20px',
                                height: '20px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                transform: 'translateY(-50%)',
                              }} />
                            </div>
                          </div>
                        )
                      })}
                    </div> : ""
                    }
                  </div> :
                    <div className="d-flex align-items-center justify-content-center p-4 ms-2">
                      {busDataInfo?.booking_type_seat_counts?.available_seats_count > 0 ?
                        <h4 className="">{`Hurry Up only ${busDataInfo?.booking_type_seat_counts?.available_seats_count} seats are left`}</h4> :
                        <h4>Sorry No Seats Availble for the Day!</h4>}
                    </div>
                }

              </div>

            </div>
            <div className="col-md-5 col-12 mt-md-0 mt-2">
              <div className="shadow-card "
                style={{
                  borderRadius: "13px",
                }}>
                <div className="p-md-3 p-2 busCard">
                  <div className="d-flex align-items-center justify-content-between">
                    Electric AC Premium (2+2)
                  </div>
                </div>
                <div className="p-lg-4 p-md-3 p-3 mt-md-0"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pdBooking2">{busDataInfo?.origin?.name + "-" + busDataInfo?.destination?.name}</div>
                    <div className="pdBooking2">{busDataInfo.dep_time}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="bookingHead3">{formatDateWithDay(busDataInfo?.dep_date)}</div>
                    <div className="bookingHead3">{formatDateWithDay(busDataInfo?.arr_date)}</div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <div className="d-flex flex-column">
                        <div className="pdBooking3 mt-3">{busDataInfo?.origin?.name}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column">
                        <div className="text-center bookingHead3">
                          {busDataInfo?.duration}
                        </div>
                        <div className="dashBorder2 mt-1">
                          <div className="line2"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 text-end">
                      <div className="d-flex flex-column">
                        <div className="pdBooking3 mt-3">{busDataInfo?.destination?.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {couponData?.length > 0 ?
                <div
                  className="mt-3 shadow-card"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <div className="p-2 offerCard">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ms-1">
                        <div className="pdOffer">Eco-friendly rides,savings–Your Green Travel Awaits!</div>
                        {/* <div className="pdOffer">Apply Coupons & Travel Smarter!</div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <MdLocalOffer size={24} color="white" />
                      </div>
                    </div>
                  </div>
                  <div className="p-md-2 p-3">
                    <CouponCard
                      couponData={couponData}
                      onCouponSelect={handleCouponSelect}
                      totalDiscount={totalDiscount}
                      passengerDetail={passengerDetail} />
                  </div>
                </div>
                : ""
              }
              <div className="container text-center pdBill mt-4">
                {" "}
                Discounts, offers and price concessions will be applied later
                during payment
              </div>
              <div className="mt-3">
                <button
                  disabled={isLoading || emailErrorMsg || mobileErrorMsg || passengerDetail?.length === 0}
                  className="btn btn-secondary w-100"
                  type="button" id="ebz-checkout-btn"
                  onClick={handleTicketBooking}>
                  {isLoading ? <Spinner size="sm" color="light" /> : "Make a Payment"}
                </button>
              </div>
              <BillDetail
                passengerDetail={passengerDetail}
                totalTax={totalTax}
                totalDiscount={totalDiscount}
                totalTicket={totalTicket}
              />
            </div>
          </div>
        </div>
      </div>

    </React.Fragment >
  );
};

export default PassengerDetails;
