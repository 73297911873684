import React, { useState } from 'react'
import { BiSolidOffer } from "react-icons/bi";
import { auth } from '../services/firebaseconfig';
import Login from '../pages/Auth/Login';
import { CouponModal } from './CouponModal';
import { RiArrowDropRightLine } from 'react-icons/ri';
import { GiElectric } from 'react-icons/gi';
import { PiWarningCircleFill } from "react-icons/pi";
import { IoIosCheckmark } from "react-icons/io";
import toast from 'react-hot-toast';

const CouponCard = ({ couponData, onCouponSelect, totalDiscount, passengerDetail }) => {
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState("");
    const [couponInput, setCouponInput] = useState("");
    const [couponError, setCouponError] = useState("");
    const [loginError, setLoginError] = useState("");


    // Function to apply Coupon--
    const handleApplyClick = (coupon) => {
        const userID = auth?.currentUser?.uid;
        if (userID) {
            if (passengerDetail.length > 0) {
                if (selectedCoupon === coupon?.couponCode) {
                    setSelectedCoupon(null);
                    onCouponSelect(null);
                } else {
                    setSelectedCoupon(coupon?.couponCode);
                    onCouponSelect(coupon);
                    setIsModalOpen(true);
                    setData(coupon);
                }
            }
            else {
                toast.error("Select a LEAFY Seat");
                return;
            }
        }
        else {
            setIsOpen(true);
        }
    }

    // Function to handle the Coupon code by entering
    const handleInputApplyClick = () => {
        const userID = auth?.currentUser?.uid;
        if (userID) {
            if (passengerDetail?.length > 0) {
                const matchingCoupon = couponData?.find(
                    (coupon) => coupon?.couponCode === couponInput?.trim()
                );
                if (matchingCoupon) {
                    setSelectedCoupon(matchingCoupon?.couponCode);
                    onCouponSelect(matchingCoupon);
                    setIsModalOpen(true);
                    setData(matchingCoupon);
                    setCouponError(false);
                    setCouponInput("");
                } else {
                    setCouponError(true);
                }
            }
            else {
                setLoginError("Select a LEAFY Seat");
                return
            }
        }
        else {
            setLoginError("Please login first!");
        }

    }
    const nonPromoCoupon = couponData?.find((item) => !item.isForPromo);

    return (
        <React.Fragment>
            <div className="">
                <div className="d-flex align-items-center justify-content-between w-100 mt-2">
                    {selectedCoupon ? (
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center">
                                <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                                <div className="ms-2 pdOffer1">{`₹${totalDiscount} saved with ${selectedCoupon}`}</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <IoIosCheckmark size={24} color="green" />
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ fontSize: "12px", fontWeight: 600, color: "green" }}
                                >
                                    Applied
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {nonPromoCoupon ? (
                                <div className="d-flex align-items-center w-100">
                                    <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                                    <div className="d-flex align-items-center pdOffer1 ms-2 flex-grow-1">
                                        {nonPromoCoupon?.flatOff
                                            ? `Flat ₹${nonPromoCoupon?.flatOff} off | Use Code ${nonPromoCoupon?.couponCode}`
                                            : `${nonPromoCoupon?.percentage}% off upto ₹${nonPromoCoupon?.upto} | Use Code ${nonPromoCoupon?.couponCode}`}
                                    </div>
                                    <div
                                        className={`pdOfferApply ms-auto ${selectedCoupon === nonPromoCoupon?.couponCode ? "text-danger" : ""
                                            }`}
                                        onClick={() => handleApplyClick(nonPromoCoupon)}
                                        disabled={selectedCoupon === nonPromoCoupon?.couponCode}
                                    >
                                        {selectedCoupon === nonPromoCoupon?.couponCode ? "REMOVE" : "APPLY"}
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center pdOffer1 ms-2">
                                    No eligible coupons available at the moment.
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <hr />
                <div
                    className="d-flex align-items-center ms-md-2 ms-1 pointer"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                >
                    <div className='text-muted'>
                        View all coupons
                        <RiArrowDropRightLine size={28} />
                    </div>
                </div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">APPLY COUPON</h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className=" border border-gray rounded-4 mt-4">
                            <div className="row">
                                <div className="col-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required
                                        placeholder="Enter LEAFY Coupon Code"
                                        value={couponInput}
                                        onChange={(e) => {
                                            setCouponInput(e.target.value.toUpperCase())
                                            setCouponError("");
                                            setLoginError("");
                                        }
                                        }
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            boxShadow: 'none',
                                            padding: '10px',
                                            color: "green",
                                            fontWeight: 500
                                        }}
                                    />
                                </div>
                                <div className="col-4">
                                    <div
                                        className={` pdOfferApply d-flex align-items-center justify-content-end me-4 h-100 pointer ${!couponInput.trim() ? "text-muted" : "text-dark"
                                            }`}
                                        onClick={couponInput.trim() ? handleInputApplyClick : null}
                                        style={{
                                            cursor: couponInput.trim() ? "pointer" : "not-allowed",
                                            fontWeight: "800",
                                            fontSize: "13px",
                                        }}
                                    >
                                        APPLY
                                    </div>
                                </div>
                            </div>
                        </div>
                        {couponError ?
                            <div className='d-flex align-items-center'>
                                <div><PiWarningCircleFill color='green' /></div>
                                <span className='text-danger ms-2' style={{ fontSize: "12px", fontWeight: 500 }}>Invalid coupon code</span>
                            </div>
                            : ""}
                        {loginError ? <span className='text-danger ms-2' style={{ fontSize: "12px", fontWeight: 500 }}>{loginError}</span> : ""}
                        <h5 className='mt-3'>More Offers</h5>
                        <div className='mt-2 ms-2'>
                            {couponData?.map((item, index) => (
                                !item?.isForPromo ? (
                                    <div className="rounded-4 shadow-card mt-3" key={index}>
                                        <div className="row">
                                            <div
                                                className="col-1 p-1"
                                                style={{
                                                    writingMode: "vertical-rl",
                                                    background: "#17ff17",
                                                    borderTopLeftRadius: "14px",
                                                    borderBottomLeftRadius: "14px",
                                                }}
                                            >
                                                <div className="d-flex align-items-start justify-content-center">
                                                    <div
                                                        className="text-dark"
                                                        style={{
                                                            fontSize: "17px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {item?.flatOff !== "" ? "FLAT OFF" : `${item?.percentage}% OFF`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-11 p-3">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <div
                                                                style={{
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {item?.couponCode}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                <GiElectric size={20} color="green" />
                                                                {`Save ${item.flatOff !== ""
                                                                    ? `₹${item?.flatOff}`
                                                                    : `upto ₹${item?.upto}`
                                                                    } on every trip`}
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div
                                                                className={`pdOfferApply ms-auto ${selectedCoupon === item?.couponCode ? "text-danger" : ""
                                                                    }`}
                                                                onClick={() => handleApplyClick(item)}
                                                                disabled={selectedCoupon === item?.couponCode}
                                                                style={{
                                                                    fontSize: "14px",
                                                                    color: "black",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {selectedCoupon === item?.couponCode ? "REMOVE" : "APPLY"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div
                                                        style={{
                                                            fontSize: "13px",
                                                            fontWeight: 800,
                                                            color: "green",
                                                        }}
                                                    >
                                                        Use code{" "}
                                                        <span style={{ color: "black" }}>{item?.couponCode}</span> and get{" "}
                                                        {`${item.flatOff !== ""
                                                            ? `₹${item?.flatOff}`
                                                            : `upto ₹${item?.upto}`
                                                            } on LEAFY trip`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ?
                <Login
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                /> : ""}
            {isModalOpen ?
                <CouponModal
                    data={data}
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    selectedCoupon={selectedCoupon}
                    totalDiscount={totalDiscount}
                /> : ""}
        </React.Fragment>
    )
}

export default CouponCard