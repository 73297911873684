import React, { useState, useEffect } from "react";
import { Modal, ModalBody, TabContent, TabPane } from "reactstrap";
import { MdClose } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { FaBottleWater } from "react-icons/fa6";
import { FaPersonMilitaryPointing } from "react-icons/fa6";
import { AiOutlineUsb } from "react-icons/ai";
import { CiLight } from "react-icons/ci";
import { MdLiveTv } from "react-icons/md";
import { TfiGift } from "react-icons/tfi";
import { getSeatData } from "../services/allApi";
import Loader from "./Loader/Loader";

const InfoModal = ({ infoModal, setInfoModal, selectedItem, data }) => {
    const [activeTab, setActiveTab] = useState(selectedItem || "About");
    const [policyData, setPolicyData] = useState([]);
    const [stageDetail, setStageDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedItem) {
            setActiveTab(selectedItem);
        }
    }, [selectedItem]);

    const toggle = () => {
        setInfoModal(false);
    };

    const busDetails = [
        {
            icon: <TbAirConditioning color="#4F4F4F" />,
            title: "Air Conditioning",
        },
        {
            icon: <FaBottleWater color="#4F4F4F" />,
            title: "Water Bottles",
        },
        {
            icon: <MdLiveTv color="#4F4F4F" />,
            title: "Display Board",
        },
        {
            icon: <CiLight color="#4F4F4F" />,
            title: "Reading Lights",
        },
        {
            icon: <AiOutlineUsb color="#4F4F4F" />,
            title: "USB and Type C Charging point",
        },
        {
            icon: <FaPersonMilitaryPointing color="#4F4F4F" />,
            title: " Professional Bus Buddy",
        },
        {
            icon: <TfiGift color="#4F4F4F" />,
            title: "Welcome Kit",
        },
    ];
    // Function to fetch the cancellation policy----
    const fetchPolicy = async () => {
        try {
            setIsLoading(true)
            let reservID = data?.reservation_id;
            let originID = data?.origin_id;
            let destID = data?.destination_id;
            let boardID = data?.boarding_point_details[0]?.id;
            let dropID = data?.drop_off_details[0]?.id;
            const response = await getSeatData(reservID, originID, destID, boardID, dropID);
            setPolicyData(response?.data?.cancellation_policies);
            setStageDetail(response?.data?.stage_details)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    useEffect(() => {
        fetchPolicy();
    }, [])
    return (
        <React.Fragment>
            {isLoading ? <Loader /> : ""}
            <Modal isOpen={infoModal} toggle={toggle} centered size="lg">
                <ModalBody
                    style={{ padding: 0, overflow: "hidden", borderRadius: "16px" }}
                >
                    <div style={{ margin: 0, padding: 0 }}>
                        <div
                            className="row m-0 p-md-3 p-1  modalFeature1"
                        >
                            <div className="text-end mt-0" onClick={toggle} style={{ cursor: "pointer" }}>
                                <MdClose />
                            </div>
                            <div
                                className="d-flex mb-3 modalFeature"
                            >
                                {["About", "Amenities", "Bus Route", "Policies"]?.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className="modalFeature2"
                                        style={{
                                            fontWeight: activeTab === tab ? "bold" : "normal",
                                            color: activeTab === tab ? "darkgreen" : "black",
                                            borderBottom: activeTab === tab ? "3px solid darkgreen" : "transparent",
                                        }}
                                    >
                                        {tab}
                                    </div>
                                ))}
                            </div>

                            {/* Tab Content */}
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="About">{activeTab === "About" &&
                                    <React.Fragment>
                                        <div className="dropLables mt-4">About Leafy Bus</div>
                                        <div className="d-flex align-items-center justify-between mt-3">
                                            <div>
                                                <img src="/images/dropBus.png" alt="Bus1" width="95%" />
                                            </div>
                                            <div>
                                                <img src="/images/dropBus.png" alt="Bus1" width="95%" />
                                            </div>
                                        </div>
                                        <div className="mt-md-5 mt-4 mb-4 busText">
                                            LeafyBus is a highly esteemed and renowned brand in the bus
                                            operating industry. Our vision is to revolutionize and give a
                                            completely new face to the bus industry. Since our inception,
                                            ensuring passenger comfort and satisfaction has been our top and
                                            unwavering priority. We have consistently and frequently added
                                            state-of-the-art luxury buses to our extensive and diverse fleet
                                            of buses. The only thing we focus on is that the comfort and
                                            well-being quotient of our passengers should never be
                                            compromised, no matter the circumstances. We have always strived
                                            and endeavored to push our limits and boundaries in order to
                                            elevate and continuously improve our travel experience. Continue
                                            reading further to explore and understand what we offer that
                                            significantly enhances and solidifies our stellar reputation in
                                            the market.
                                        </div>
                                    </React.Fragment>
                                }</TabPane>
                                <TabPane tabId="Amenities">{activeTab === "Amenities" &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <div className="busInfoLabels mt-4">Services</div>
                                                {busDetails?.map((item) => {
                                                    return (
                                                        <div className="d-flex align-items-center mt-2 busInfotext">
                                                            <div className="d-flex align-items-center">
                                                                {item?.icon}
                                                            </div>
                                                            <div className="d-flex align-items-center ms-3">
                                                                {item?.title}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="busInfoLabels mt-4">General Information</div>
                                                <div className="mt-2 busInfotext2">Carry-on Luggage Size</div>
                                                <div className="mt-1 busInfotext2">
                                                    45 х 35 х 20 cm (less 5 kg)
                                                </div>
                                                <div className="mt-2 busInfotext2">Trunk luggage</div>
                                                <div className="mt-1 busInfotext2">
                                                    70 х 30 х 55 см (less 30 kg)
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }</TabPane>
                                <TabPane tabId="Bus Route">{activeTab === "Bus Route" && <React.Fragment>
                                    <div className="col-md-6 col-12">
                                        <div className="busInfoLabels mt-3">Bus Route</div>
                                        <div className="stepper d-flex flex-column mt-3 ml-2 mb-3">
                                            {stageDetail?.map((item, index) => (
                                                <div key={index}>
                                                    <div className="d-flex gap-4">
                                                        <div className="text">{item?.time}</div>
                                                        <div className="d-flex">
                                                            <div className="d-flex flex-column align-items-center mt-0">
                                                                <div className="rounded-circle p-1 bg-white border border-black text-white"></div>
                                                                <div className="line h-100"></div>
                                                                {index === stageDetail?.length - 1 ? <div className="rounded-circle p-1 bg-white border border-black text-white"></div> : ""}

                                                            </div>
                                                            <div className="ms-2 d-flex flex-column align-items-center mt-3 pb-3">
                                                                <div className="text-dark">{item?.name}</div>
                                                                <div className="lead text-muted">
                                                                    Bus Station
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>}</TabPane>
                                <TabPane tabId="Policies">
                                    {activeTab === "Policies" && <div>
                                        <div style={{
                                            width: '100%',
                                            margin: '20px 0',
                                            border: '2px solid black', // Apply border to wrapper
                                            borderRadius: '10px', // Rounded corners for the table
                                            overflow: 'hidden', // Ensure content doesn't exceed rounded corners
                                        }}>
                                            <table style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                fontSize: '16px',
                                                textAlign: 'left',
                                            }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{
                                                            // border: '1px solid black',
                                                            padding: '10px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                        }}>
                                                            Time Before Travel
                                                        </th>
                                                        <th style={{
                                                            borderLeft: '1px solid black',
                                                            padding: '10px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                        }}>
                                                            Deduction(%)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {policyData?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td style={{
                                                                borderTop: "1px solid black",
                                                                borderRight: "1px solid black",
                                                                padding: '10px',
                                                                textAlign: 'left',
                                                            }}>
                                                                {`From ${item.time_limit_from} Until ${item.time_limit_to}`}
                                                            </td>
                                                            <td style={{
                                                                borderTop: '1px solid black',
                                                                borderLeft: "1px solid black",
                                                                padding: '10px',
                                                                textAlign: 'left',
                                                            }}>
                                                                {item.percent}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>}
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default InfoModal;
