import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import Connect from "../../components/Connect";

export const Contactus = () => {
  const infoData = [
    {
      id: "1",
      title: "Have any question?",
      subTitle: "+91 9547-121-121",
      icon: <IoCallOutline color="green" size={32} />,
    },
    {
      id: "2",
      title: "Write email",
      subTitle: "connect@leafybus.com",
      icon: <CiMail color="green" size={32} />,
    },
    {
      id: "3",
      title: "Visit anytime",
      subTitle: "203-A Naurang House KG MARG Connaught Place New Delhi 110001",
      icon: <IoLocationOutline color="green" size={32} />,
    },
  ];





  return (
    <div className="contentSection">
      <div className="contactBanner">
        <div className="p-3">
          <div>Contact Us</div>
          <div className="contactText d-flex-align-items-center justify-content-center">
            Feel Free to Reach Us
          </div>
        </div>
      </div>
      <div className="p-md-5 p-sm-5 p-3">
        <div className="row p-lg-5 p-1">
          <div className="col-xxl-5 col-lg-6 col-12 d-flex  justify-content-center">
            <img src="/images/contact.png" alt="contactbus" width="100%" />
          </div>
          <div className="col-xxl-6 col-lg-6 col-12">
            <div className="container">
              <div className="d-flex flex-column p-md-4 p-2  pt-md-0 pt-3 mt-md-3 mt-0">
                <div className="contHeader mt-3 mt-xxl-0">
                  Get in touch with LEAFY
                </div>
                <div className="mt-3 contSubHeader text-wrap">
                  Connect with LEAFY for seamless support and innovative solutions, tailored just for you!
                </div>
                {infoData?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      className="d-flex mt-xl-5 mt-lg-4 mt-md-3 mt-3 gap-3"
                    >
                      <div
                        className="d-flex align-items-center justify-content-center rounded-circle iconFeature"
                      >
                        {item?.icon}
                      </div>
                      <div className="d-flex flex-column">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "rgba(125, 125, 125, 1)",
                          }}
                        >
                          {item?.title}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "rgba(32, 34, 36, 1)",
                          }}
                        >
                          {item?.title === "Write email" ? (
                            <a
                              href="mailto:connect@leafybus.com?subject=Customer%20Support&body=Hello%20LeafyBus,%20I%20need%20assistance%20with..."
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'black', textDecoration: 'none' }}
                            >
                              {item?.subTitle}
                            </a>
                          ) : (
                            item?.subTitle
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Connect title={"Request A Call Back !"} />
        </div>
      </div>
    </div>
  );
};
