

// export let baseUrl = "/operator/api/"
export const API = {
  Allapi: {
    getDestinations: "destination_pairs.json",
    getRoutes: "available_routes",
    getSeatData: "service_details",
    bookETicket: "book_e_ticket",
    ConfirmETicket: "confirm_tentative_ebooking",
    CanCancel: "/can_cancel.json",
    CancelETicket: "cancel_e_ticket.json"
  },
};
