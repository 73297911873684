import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { IoLogInOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footerBanner text-white ">
      <div className="container pt-md-5 pt-sm-4 pt-3">
        <div className="row">
          <div className="col-lg-4 col-md-7  col-sm-7 col-6">
            <div className="d-flex justify-content-start w-100">
              <a href="/">
                <img src="footerLogo.png" alt="Logo" className="footerLogo" />
              </a>

            </div>
            <div style={{ color: "rgba(158, 150, 150, 1)", fontSize: "14px", fontWeight: 500 }}>
              <p>
                By providing a seamless platform
                <br />
                for booking eco-friendly bus rides,
                <br />
                we are committed to reducing
                <br />
                carbon footprints and enhancing
                <br />
                Travel convenience.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-5 col-sm-5 col-6 mt-lg-0  mt-2 ">
            <div className="footerHeader">QUICK LINKS</div>
            <div className="d-flex flex-column gap-1">
              <ul className="list-unstyled mb-0 mt-3 d-flex flex-column gap-2">
                {/* <li className="">
                  <a href="#!" className="footerLabel text-decoration-none">
                    About us
                  </a>
                </li> */}
                <li className="">
                  <a href="/terms-conditions-leafybus" className="footerLabel text-decoration-none">
                    Terms & Conditions
                  </a>
                </li>
                <li className="">
                  <a href="/privacypolicy" className="footerLabel text-decoration-none">
                    Privacy Policy
                  </a>
                </li>
                {/* <li className="">
                  <a href="#!" className="footerLabel text-decoration-none">
                    Pricing
                  </a>
                </li> */}
                <li className="">
                  <a href="/contact-leafybus" className="footerLabel text-decoration-none">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 col-sm-7 col-6  mt-lg-0  mt-3">
            <div className="footerHeader">CONTACT US</div>
            <div className="d-flex flex-column gap-3 mb-0 mt-3">
              <div className="d-flex align-items-center">
                <div className="footerLabel">
                  <CiLocationOn />
                </div>
                <div className="footerLabel" style={{ marginLeft: "5px" }}>
                  203-A Naurang House
                  KG MARG
                  Connaught Place
                  New Delhi-110001
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="footerLabel">
                  <IoCallOutline />
                </div>
                <div className="footerLabel" style={{ marginLeft: "5px" }}>
                  9547-121-121
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="footerLabel">
                  <CiMail />
                </div>
                <a href="mailto:connect@leafybus.com?subject=Customer%20Support&body=Hello%20LeafyBus,%20I%20need%20assistance%20with..."
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}>

                  <div className="footerLabel" style={{ marginLeft: "5px" }}>
                    connect@leafybus.com
                  </div>
                </a>

              </div>
              <a
                href="https://leaf.ticketsimply.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="d-flex align-items-center pointer">
                  <div className="footerLabel">
                    <IoLogInOutline />
                  </div>
                  <div className="footerLabel" style={{ marginLeft: "5px" }}>
                    Agent Login
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-5 col-sm-5 col-6  mt-lg-0  mt-3">
            <div className="footerHeader">SOCIAL MEDIA</div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
                <img src="/images/fb.png" alt="facebooklogo" />
                <img src="/images/xlogo.png" alt="facebooklogo" />
                <a href="https://www.instagram.com/_leafybus?igsh=dnd5c3QxN2Y0b3M4" target="_blank" rel="noopener noreferrer">
                  <img src="/images/insta.png" alt="Instagram Logo" />
                </a>
                <a href="https://www.linkedin.com/company/leafybus/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/linkd.png" alt="LinkedIn Logo" />
                </a>
              </div>
            </div>
            <div className="footerHeader mt-4">NEWSLETTER</div>
            <div
              style={{
                color: "rgba(158, 150, 150, 1)",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              <p>
                Stay Updated with Leafy Bus: Sign Up for Exclusive News &
                Offers!
              </p>
            </div>

            <form >
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  type="email"
                  required
                  placeholder="Your Email Address"
                  style={{
                    color: "white",
                    outline: "none",
                    width: "100%",
                    border: "gray",
                    background: "rgba(28, 28, 28, 1)",
                    padding: "8px 40px 8px 8px",
                    fontSize: "12px",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                  }}
                  className="py-2 inputField"
                />
                <button
                  type="submit"
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "12px",
                    cursor: "pointer",
                    border: "none",
                    color: "rgba(23, 255, 23, 1)",
                    borderRadius: "4px",
                    background: "rgba(28, 28, 28, 1)",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
      <div className="mt-5">
        {" "}
        <hr />
      </div>

      <div className="container mt-4 mb-md-3 mb-5">
        <div style={{ fontSize: "16px", fontWeight: 300 }}>
          Copyright &copy; 2024 Leafymobility Private Limted. All right reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
