import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoIosWarning } from "react-icons/io";
import { deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../services/firebaseconfig';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const DeleteModal = ({ isOpen, setIsOpen }) => {
    const toggle = () => {
        setIsOpen(false);
    }

    const navigate = useNavigate();

    const handleDelete = async () => {
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            try {
                await deleteDoc(userRef);
                toast.success('Your account  has been successfully deleted.');
                localStorage.clear();
                navigate("/");
            } catch (error) {
                toast.error("Error deleting account data: " + error.message);
            }
        } else {
            toast.error("No user logged in.");
        }
    };


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} centered size="md">
                <ModalBody>
                    {/* <div className='d-flex align-items-center justify-content-center'>
                        <IoIosWarning color='red' size="60" />
                    </div> */}
                    {/* <div
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className='d-flex align-items-center justify-content-center'>
                        Whoops!
                    </div> */}
                    <div className='d-flex align-items-center justify-content-center mt-3'>
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            Are you sure you want to  Delete your account!
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center justify-content-center mt-3'>
                        <div style={{ fontSize: "20px", fontWeight: "600", color: "rgba(33, 136, 33, 1)" }}>
                            LEAFYBUS
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-1'>
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            Thank you!
                        </div>
                    </div> */}


                    <div className='d-flex align-items-center justify-content-end me-5 mt-5'>
                        <div className=''>
                            <span className="me-5 pointer" onClick={toggle} >Cancel</span>
                        </div>
                        <div className=''>
                            <button className='btn btn-edit pointer' color="danger" onClick={handleDelete}>Delete</button>
                        </div>
                    </div>
                </ModalBody>

            </Modal>
        </React.Fragment>
    )
}

export default DeleteModal