import React from "react";
import Testimonial from "./Testimonial";
import Connect from "../../components/Connect";
import GalleryImage from "./GalleryImage";

const GalleryView = () => {
  return (
    <div className="contentSection">
      <div className="galleryBanner">
        <div className="row">
          <div className="col-md-6 col-4"></div>
          <div className="col-md-6 col-8">
            <div className="p-3 text-center">
              <div className="contactText">Gallery</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <GalleryImage />
      </div>
      <div className="mt-3 container">
        <Connect title={"Request A Call Back !"} />
      </div>
      {/* <div className="mt-5 mb-5">
        <Testimonial />
      </div> */}
    </div>
  );
};

export default GalleryView;
