import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { formatDateWithDay } from '../helper';
import { cancelBooking, CancelETicket } from '../services/allApi';
import Loader from './Loader/Loader';
import CancelledModal from './CancelledModal';
import { Spinner } from 'reactstrap';

const CancelModal = ({ isOpen, toggle, bookingData, cancelData, isLoading2 }) => {

    const [CancelModal, setCancelModal] = useState(false);
    const [cancelInfo, setCancelInfo] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const res = formatDateWithDay(bookingData?.arr_date);



    const handleCancellation = async () => {

        setCancelModal(true);
        try {
            setIsLoading(true)
            let payload = bookingData;
            const response = await CancelETicket(payload);
            setCancelInfo(response?.data);
            if (response?.data) {
                let payload = bookingData?.ticket_number;
                const response = await cancelBooking(payload);
            }
            setIsLoading(false)
            toggle();
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            {isLoading2 ? <Loader /> : ""}
            <Modal isOpen={isOpen} toggle={toggle} centered>
                <ModalBody>
                    <div className="card shadow-sm p-1 mb-5 bg-white rounded" style={{ width: '100%' }}>
                        <div className="card-body">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <p className="mb-2">Ticket Number {bookingData?.ticket_number}</p>
                                    <p className="card-subtitle text-muted mb-4">AC Chair Economy</p>
                                </div>
                                <div>
                                    <img
                                        src="https://via.placeholder.com/40"
                                        alt="QR Code"
                                        className="rounded"
                                        style={{ width: "75px", height: "75px" }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mt-2">
                                <div className="col-4" style={{ fontSize: "15px" }}>{bookingData?.origin}</div>
                                <div className="col-2 dashBorder">
                                    <div className="line"></div>
                                </div>
                                <div className="col-2 dashBorder1">
                                    <div className="line1"></div>
                                </div>
                                <div className="col-4" style={{ fontSize: "15px" }}>{bookingData?.destination}</div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <span className="text-muted">{bookingData?.arr_time}</span>
                                <span className="text-muted">{res}</span>
                            </div>
                        </div>
                    </div>
                    <div className='card shadow-sm p-3 mt-3 rounded' style={{ background: "#E6FFE6" }}>
                        <div className='d-flex justify-content-between'>
                            <div className=''>Cancellation(%)</div>
                            <div className=''>{cancelData?.cancel_percent}%</div>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className=''>Cancellation Fee</div>
                            <div className=''>₹{cancelData?.cancelled_fare}</div>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className=''>Refund Amount</div>
                            <div className=''>₹{cancelData?.refund_amount}</div>
                        </div>
                    </div>
                    <div className='mt-5 text-center'>
                        <button
                            className='btn btn-auth w-75'
                            onClick={handleCancellation}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner color="light" size="sm" /> : "Cancel Booking"}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <CancelledModal isOpen={CancelModal} setCancelModal={setCancelModal} cancelInfo={cancelInfo} bookingData={bookingData} />
        </React.Fragment >
    )
}

export default CancelModal