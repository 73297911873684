import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { auth, db } from "./firebaseconfig";



// FUnction to check the user existence--
export const UserExistence = async (userID) => {
  try {
    const userRef = doc(db, "users", userID); // Document reference
    const userDoc = await getDoc(userRef); // Get the document
    return userDoc.exists(); // Check if the document exists
  } catch (error) {
    console.error("Error checking user existence:", error);
    return false; // Return false if there is an error
  }
};

// Function to add booking data to Firestore(with userID & without userID )
export const AddBookingData = async (ticketInfo, txnid, easeBuzzTransactionId) => {
  try {
    const user = auth?.currentUser?.uid || "anonymous";
    const dataWithUserID = {
      ...ticketInfo,
      userId: user,
      transaction_id: txnid,
      easeBuzzTransactionId: easeBuzzTransactionId,
      createdAt: serverTimestamp(),
    };
    await addDoc(collection(db, 'bookingDB'), dataWithUserID);
    return true

  } catch (e) {
    console.error('Error adding document: ', e);
    return false

  }
};

// Function to add booking data to Firestore(with userID & without userID )
export const AddErrorData = async (data, type) => {
  try {
    const errorData = {
      error: data,
      errorType: type,
      createdAt: serverTimestamp(),
    };
    await addDoc(collection(db, 'errorDB'), errorData);
    return true

  } catch (e) {
    console.error('Error adding document: ', e);
    return e
  }
};


// Function to get the ticekt detail on the basis of the PNR Number--
export const getPNRData = async (payload) => {
  let pnr = payload?.pnr;
  try {
    const bookingCollection = collection(db, "bookingDB");
    const userQuery = query(bookingCollection, where("ticket_number", "==", pnr));
    const querySnapshot = await getDocs(userQuery);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return data;
  } catch (error) {
    console.error("Error fetching data from Firestore", error);
    return [];
  }
};