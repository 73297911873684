import React, { useState } from 'react';
import { totalFare } from '../helper';
import { IoMdArrowDropup } from "react-icons/io";
import { MdOutlineArrowDropDown } from "react-icons/md";

const BillDetail = ({ totalTicket, totalDiscount, totalTax, passengerDetail }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <div className="p-md-4 p-3 border border-gray rounded mt-3">
                <div
                    className="pdBooking1 d-flex align-items-center justify-content-between pointer"
                    onClick={toggleOpen}
                >
                    {totalTicket > 0 ? (
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <span>Total(incl tax)</span>
                            <span>{isOpen ?
                                <div>
                                    {`₹${totalTicket}`}
                                    <IoMdArrowDropup size={26} />
                                </div> :
                                <div>
                                    {`₹${totalTicket}`}
                                    <MdOutlineArrowDropDown size={30} />
                                </div>
                            }</span>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <span>Bill Details</span>
                            <span>{isOpen ? <IoMdArrowDropup size={26} /> : <MdOutlineArrowDropDown size={30} />}</span>
                        </div>
                    )}
                </div>

                <div className={`collapse ${isOpen ? 'show' : ''}`}>
                    <div className="d-flex flex-column mt-3">
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="pdBill">Base Ticket Fare</div>
                            <div className="pdBill">{`₹${totalFare(passengerDetail)}.00`}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="pdBill">Total Travellers</div>
                            <div className="pdBill">{passengerDetail?.length}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="pdBill">GST</div>
                            <div className="pdBill">{`₹${totalTax}`}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="pdBill">DISCOUNT OFFERED</div>
                            <div className="pdBill">{`₹${totalDiscount}`}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="pdBooking1">Total Charge(tax inclusive)</div>
                            <div className="pdBooking1">{`₹${totalTicket}`}</div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default BillDetail;
