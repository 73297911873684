import React from 'react'
import { TiTick } from 'react-icons/ti'

export const Tickanimation = () => {
    return (
        <div className="dotted-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}


export const Tick = () => {
    const color = "#31A91D";
    const tickColor = "#FFFFFF"

    return (
        <div
            style={{
                width: "75px",
                height: "75px",
                margin: 'auto',
                display: 'inline-block',
            }}
        >
            <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
            >
                <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill={color}
                />
                <path
                    className="checkmark__check"
                    fill="none"
                    stroke={tickColor}
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
            </svg>
        </div>
    )
}