import "./App.css";
import "./pages/HomePage/Common.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { DataProvider } from "./services/DataContext";
import { analytics } from "./services/firebaseconfig";
import { logEvent } from "firebase/analytics";

function App() {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");

    if (analytics) {
      if (source === "qr_code") {
        logEvent(analytics, "user_opened_from_qr");
      } else {
        logEvent(analytics, "user_opened_directly");
      }
    } else {
      console.error("Firebase Analytics is not initialized.");
    }
  }, []);


  return (
    <React.Fragment>
      <DataProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <div id="recaptcha-container" style={{ display: "none" }}></div>
        <Toaster
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </DataProvider>

    </React.Fragment>
  );
}

export default App;
